<template>
  <div class="modal-overlay" v-if="showModal">
    <div class="modal-content">
      <!-- Modal close button -->
      <span class="close-btn" @click="closeModal">&times;</span>

      <!-- Modal title -->
      <h2>Detail Data Pelamar</h2>

      <!-- Display fields in a two-column table -->
      <table class="detail-table">
        <tr>
          <th>Nama Lengkap</th>
          <td>{{ selectedItem[0]?.nama_lengkap || "-" }}</td>
          <th>Status Perkawinan</th>
          <td>{{ selectedItem[0]?.status_perkawinan || "-" }}</td>
        </tr>
        <tr>
          <th>No Telepon</th>
          <td>{{ selectedItem[0]?.no_telepon || "-" }}</td>
          <th>Pengalaman Kerja</th>
          <td>{{ selectedItem[0]?.pengalaman_kerja || "-" }}</td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{{ selectedItem[0]?.email || "-" }}</td>
          <th>Sumber Informasi</th>
          <td>{{ selectedItem[0]?.sumber_informasi || "-" }}</td>
        </tr>
        <tr>
          <th>Tempat Lahir</th>
          <td>{{ selectedItem[0]?.tempat_lahir || "-" }}</td>
          <th>Deskripsi Pengalaman Kerja</th>
          <td>{{ selectedItem[0]?.deskripsi_pengalaman_kerja || "-" }}</td>
        </tr>
        <tr>
          <th>Tanggal Lahir</th>
          <td>{{ selectedItem[0]?.tanggal_lahir || "-" }}</td>
          <th>Perusahaan Sebelumnya</th>
          <td>{{ selectedItem[0]?.perusahaan_before || "-" }}</td>
        </tr>
        <tr>
          <th>Jenis Kelamin</th>
          <td>{{ selectedItem[0]?.jenis_kelamin || "-" }}</td>
          <th>Tanggal Mulai Sebelumnya</th>
          <td>{{ selectedItem[0]?.tgl_mulai_before || "-" }}</td>
        </tr>
        <tr>
          <th>Agama</th>
          <td>{{ selectedItem[0]?.agama || "-" }}</td>
          <th>Tanggal Selesai Sebelumnya</th>
          <td>{{ selectedItem[0]?.tgl_selesai_before || "-" }}</td>
        </tr>
        <tr>
          <th>Pendidikan</th>
          <td>{{ selectedItem[0]?.pendidikan || "-" }}</td>
          <th>Posisi Terakhir</th>
          <td>{{ selectedItem[0]?.posisi_terahir || "-" }}</td>
        </tr>
        <tr>
          <th>Jurusan</th>
          <td>{{ selectedItem[0]?.jurusan || "-" }}</td>
          <th>Posisi Lamar</th>
          <td>{{ selectedItem[0]?.name_division || "-" }}</td>
        </tr>
        <tr>
          <th>Cover Letter</th>
          <td colspan="3">
            <button @click="downloadCoverLetter">Download Cover Letter</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import defaultURL from "@/config";

export default {
  props: {
    showModal: Boolean,
    selectedItem: Object,
  },
  methods: {
    closeModal() {
      this.$emit("close"); // Emit close event to parent
    },
    downloadCoverLetter() {
      // Get the file path from the selectedItem
      const filePath = this.selectedItem[0]?.cover_letter;

      if (!filePath) {
        return;
      }

      // Base URL of your API
      const fileUrl = `${defaultURL}/storage/${filePath}`;

      // Open the file in a new tab for download
      window.open(fileUrl, "_blank");
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}
.detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.detail-table th,
.detail-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top;
}
.detail-table th {
  background-color: #f4f4f4;
  width: 25%;
}
.detail-table td {
  width: 25%;
}
button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
</style>
