<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <!-- <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div> -->

      <!-- Button Add -->
      <router-link :to="{ name: 'Tambah User' }" class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nama</th>
                      <th>Email</th>
                      <th>Level</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!Users || !Users.length" class="text-center">
                      <td colspan="6">No Data</td>
                    </tr>
                    <tr v-for="(user, index) in Users" :key="user.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ user.name }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.users_level_name }}</td>
                      <td>{{ user.is_active ? "Publish" : "No" }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'Edit User',
                            params: { id: user.id },
                          }"
                          class="btn btn-primary btn-rounded btn-sm mr-1 mb-1"
                        >
                          <i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <button
                          type="button"
                          class="btn btn-dark btn-rounded btn-sm mr-1 mb-1"
                          @click="deleteUsers(user.id)"
                        >
                          <i class="fa fa-trash color-muted"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Users",

  setup() {
    const store = useStore();
    const router = useRoute();
    const currentRouteName = computed(() => router.name);
    const message = ref("You are not logged in!");
    const Users = ref([]);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get("/users", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            Users.value = data.data;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const deleteUsers = async (id) => {
      try {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        });

        if (confirmation.isConfirmed) {
          const response = await axiosInstance.delete(`/users/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          });

          if (response.status === 200) {
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            // Optionally, remove the item locally from Award array or refetch the data
            Users.value = Users.value.filter((item) => item.id !== id);
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the record.",
              "error"
            );
          }
        }
      } catch (error) {
        Swal.fire("Error!", `Error: ${error.message}`, "error");
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      Users,
      fetchData,
      deleteUsers,
    };
  },
};
</script>
