<template>
  <div class="deznav" v-if="isAuthenticated">
    <div class="deznav-scroll">
      <ul class="metismenu" id="menu">
        <!-- Dashboard (Accessible by All Roles) -->
        <li
          v-if="
            v === '1' ||
            v === '8' ||
            v === '16' ||
            v === '11' ||
            v === '17' ||
            v === '18'
          "
        >
          <router-link
            :to="{ name: 'Dashboard' }"
            class="ai-icon"
            aria-expanded="false"
          >
            <i class="flaticon-381-speedometer"></i>
            <span class="nav-text">Dashboard</span>
          </router-link>
        </li>

        <!-- Info (Super Admin, Admin, SEO) -->
        <li v-if="v === '1' || v === '8' || v === '16'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-help-1"></i>
            <span class="nav-text">Info</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Hubungi Kami' }"
                >Hubungi Kami</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Tentang Kami' }"
                >Tentang Kami</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Contact Form' }"
                >Contact Form</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Journey' }">Journey</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Bussines' }">Retail/B2B</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Kategori' }">Kategori</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Sub Kategori' }"
                >Sub Kategori</router-link
              >
            </li>
          </ul>
        </li>

        <!-- Slider (Super Admin, Admin, SEO) -->
        <li v-if="v === '1' || v === '8' || v === '16'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-album"></i>
            <span class="nav-text">Slider</span>
          </a>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Slider Hero' }"
                >Slider Hero</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Slider Product Home' }"
                >Slider Product Home</router-link
              >
            </li>
          </ul>
        </li>

        <!-- Header (Super Admin, Admin, SEO) -->
        <li v-if="v === '1' || v === '8' || v === '16'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-user-8"></i>
            <span class="nav-text">Header</span>
          </a>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'Section Head' }"
              >Data Section Page Header</router-link
            >
          </ul>
        </li>

        <!-- Post (Super Admin, Admin, SEO, CRM, Admin Produk) -->
        <li v-if="['1', '8', '16', '17', '18'].includes(v)">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-album-2"></i>
            <span class="nav-text" v-if="v === '17'">CRM</span>
            <span class="nav-text" v-else-if="v === '18'">Post Product</span>
            <span class="nav-text" v-else>Post</span>
          </a>
          <ul aria-expanded="false">
            <template v-if="v === '17'">
              <li>
                <router-link :to="{ name: 'Event BS' }"
                  >Post Event BS</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Contact Form' }"
                  >Contact Form</router-link
                >
              </li>
            </template>
            <template v-else-if="v === '18'">
              <li>
                <router-link :to="{ name: 'Product' }"
                  >Post Product</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Teknologi' }"
                  >Post Teknologi</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Knowledge' }"
                  >Post Knowledge</router-link
                >
              </li>
            </template>
            <template v-else>
              <li>
                <router-link :to="{ name: 'Award' }">Post Award</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Testimonial' }"
                  >Post Testimoni</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Event BS' }"
                  >Post Event BS</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Product' }"
                  >Post Product</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Event Recap' }"
                  >Post Event Recap</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Blog' }">Post Blog</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Teknologi' }"
                  >Post Teknologi</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Knowledge' }"
                  >Post Knowledge</router-link
                >
              </li>
            </template>
          </ul>
        </li>

        <!-- Career (Super Admin, Admin, HRD) -->
        <li v-if="v === '1' || v === '5' || v === '11'">
          <router-link
            class="has-arrow ai-icon"
            :to="{ name: 'Career' }"
            aria-expanded="false"
          >
            <i class="flaticon-381-view-2"></i>
            <span class="nav-text">Career</span>
          </router-link>
          <ul aria-expanded="false">
            <li>
              <router-link :to="{ name: 'Pelamar' }">Pelamar</router-link>
            </li>
            <li><router-link :to="{ name: 'Jobs' }">Jobs</router-link></li>
            <li>
              <router-link :to="{ name: 'Division' }">Division</router-link>
            </li>
          </ul>
        </li>

        <!-- File Manager (Super Admin, Admin, SEO, HRD, CRM, Admin Produk) -->
        <li v-if="v === '1' || v === '8' || v === '16' || v === '18'">
          <router-link
            :to="{ name: 'File Manager' }"
            class="ai-icon"
            aria-expanded="false"
          >
            <i class="flaticon-381-file-2"></i>
            <span class="nav-text">File Manager</span>
          </router-link>
        </li>

        <!-- API LOGGER & Admin (Super Admin, Admin) -->
        <li v-if="v === '1' || v === '5'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-folder-1"></i>
            <span class="nav-text">API LOGGER</span>
          </a>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'API' }">API</router-link>
            <router-link :to="{ name: 'Logger' }">Data Logger</router-link>
          </ul>
        </li>

        <li v-if="v === '1' || v === '5'">
          <a
            class="has-arrow ai-icon"
            href="javascript:void()"
            aria-expanded="false"
          >
            <i class="flaticon-381-key"></i>
            <span class="nav-text">Admin</span>
          </a>
          <ul aria-expanded="false">
            <router-link :to="{ name: 'User Level' }">User Level</router-link>
            <router-link :to="{ name: 'User List' }">Data User</router-link>
          </ul>
        </li>

        <!-- Logout -->
        <li>
          <a @click="logout" class="ai-icon" aria-expanded="false">
            <i class="flaticon-381-exit-1"></i>
            <span class="nav-text">Keluar</span>
          </a>
        </li>
      </ul>

      <div class="copyright">
        © <span id="currentYear"></span> PT. Bintang Sempurna. All Rights
        Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "LeftMenu",
  mounted() {
    const currentYear = new Date().getFullYear();
    document.getElementById("currentYear").textContent = currentYear;
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const v = computed(() => store.getters.v);

    const logout = async () => {
      try {
        const response = await axiosInstance.post(
          "/logout",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Logout successful",
            text: "You have been logged out.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", {
              token: null,
              tokenExpiry: null,
              v: null,
            });
            localStorage.clear();
            router.replace({ name: "Login" });
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle session expired case
          Swal.fire({
            title: "Session Expired",
            text: "Your session has expired or is invalid. Please log in again.",
            icon: "warning",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null, v: null });
            localStorage.clear();
            router.replace({ name: "Login" });
          });
        } else {
          Swal.fire({
            title: "Session Expired",
            text: "Your session has expired or is invalid. Please log in again.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      }
    };

    return {
      isAuthenticated,
      logout,
      v,
    };
  },
};
</script>

<style scoped>
.ai-icon {
  cursor: pointer;
}
</style>
