<template>
  <div id="main-wrapper">
    <NavHeader />
    <ChatBox />
    <Header />
    <LeftMenu />

    <!-- Body Content -->
    <router-view />
    <!-- End Body Content -->

    <!-- <Footer /> -->
  </div>
</template>

<script>
import NavHeader from "@/components/NavHeader.vue";
import ChatBox from "@/components/ChatBox.vue";
import Header from "@/components/Header.vue";
import LeftMenu from "@/components/LeftMenu.vue";
// import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavHeader,
    ChatBox,
    Header,
    LeftMenu,
    // Footer,
  },
};
</script>
