<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <!-- <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div> -->

      <!-- Button Add -->
      <button
        type="button"
        class="btn btn-primary mb-4"
        data-toggle="modal"
        data-target="#kategoriModal"
        @click="openModal()"
      >
        Tambah
      </button>
      <!-- End Button Add -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-group">
                <h4 class="card-title mb-3">
                  Cari Data {{ currentRouteName }}
                </h4>
                <!-- Search bar -->
                <div class="d-flex align-items-center">
                  <input
                    v-model="searchQuery"
                    @input="handleSearch"
                    class="form-control mr-2"
                    placeholder="Cari berdasarkan nama..."
                    style="width: 250px"
                  />
                  <button @click="handleSearch" class="btn btn-primary btn-sm">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <!-- <th>#</th> -->
                      <th>ID</th>
                      <th>Kategori</th>

                      <!-- <th>Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!filteredKategori.length" class="text-center">
                      <td colspan="3">No Data</td>
                    </tr>
                    <tr
                      v-for="kategori in paginatedKategori"
                      :key="kategori.id"
                    >
                      <td>{{ kategori.id }}</td>
                      <td>{{ kategori.judul }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <Modal :kategoriToEdit="kategoriToEdit" @refreshData="fetchData" />
</template>

<script>
import Modal from "../components/modal/Modal.vue";
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";

export default {
  name: "Kategori",
  components: {
    Modal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const currentRouteName = computed(() => router.name);
    const kategori = ref([]);
    const kategoriToEdit = ref(null);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const fetchData = async () => {
      if (
        !token.value ||
        !tokenExpiry.value ||
        new Date() > new Date(tokenExpiry.value) ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
        return;
      }
      try {
        const response = await axiosInstance.get("/kategori", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          const data = await response.data;
          kategori.value = data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }
    };

    const filteredKategori = computed(() => {
      return kategori.value.filter((item) =>
        item.judul.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const paginatedKategori = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredKategori.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredKategori.value.length / itemsPerPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const openModal = (kategori = null) => {
      kategoriToEdit.value = kategori;
      $("#kategoriModal").modal("show");
    };

    onMounted(fetchData);

    return {
      currentRouteName,
      kategori,
      kategoriToEdit,
      openModal,
      fetchData,
      searchQuery,
      handleSearch,
      filteredKategori,
      paginatedKategori,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
    };
  },
};
</script>

<style scoped>
.content-body {
  background: #f4f4f9;
}
</style>
