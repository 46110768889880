import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import axios from "./axios";
import defaultURL from "./config";

// Tentukan baseURL untuk axios menggunakan defaultURL
axios.defaults.baseURL = `${defaultURL}/api`;

axios.defaults.withCredentials = true; // Gunakan jika API membutuhkan credentials

// Membuat Axios tersedia secara global via Vue prototype
const app = createApp(App);
app.config.globalProperties.$axios = axios;

// Initialize aplikasi
app.use(store).use(router).mount("#app");
