<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form Add Events</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <!-- Section 1 -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Section 1</h4>
                </div>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Universitas</label>
                      <input
                        v-model="post.universitas"
                        @input="generateSlug"
                        type="text"
                        class="form-control"
                        placeholder="Universitas"
                      />
                    </div>
                    <div class="form-group">
                      <label>Slug</label>
                      <input
                        v-model="post.slug"
                        type="text"
                        class="form-control"
                        placeholder="Slug URL"
                        readonly
                      />
                    </div>
                    <div class="form-group">
                      <label>Hastag</label>
                      <input
                        v-model="post.hastag"
                        type="text"
                        class="form-control"
                        placeholder="Hastag"
                      />
                    </div>
                    <div class="form-group">
                      <label>Title Satu</label>
                      <input
                        v-model="post.title_satu"
                        type="text"
                        class="form-control"
                        placeholder="Title Satu"
                      />
                    </div>
                    <div class="form-group">
                      <label>Description Satu</label>
                      <textarea
                        ref="summernoteSatu"
                        class="form-control summernote"
                        v-model="post.desc_satu"
                        rows="3"
                        placeholder="Description Satu"
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <label>URL Button</label>
                      <input
                        v-model="post.url_button"
                        type="text"
                        class="form-control"
                        placeholder="URL Button"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div v-show="true" class="mb-3">
                        <img
                          :src="post.imagePreviewSatu"
                          alt="Thumbnail"
                          class="img-thumbnail"
                          style="max-width: 200px; max-height: 200px"
                        />
                      </div>
                      <div>
                        <input
                          type="file"
                          class="d-none"
                          ref="thumbnailFileInputSatu"
                          accept="image/*"
                          @change="handleFileUploadSatu"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-sm mr-1"
                          @click="triggerThumbnailFileSatu"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label>Image Hero Satu</label>
                      <input
                        @change="handleFileUpload('image_hero_satu', $event)"
                        ref="thumbnailFileInputSatu"
                        type="file"
                        class="form-control"
                      />
                    </div> -->
                  </div>
                </div>

                <!-- Section 2 -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Section 2</h4>
                </div>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div v-show="true" class="mb-3">
                        <img
                          :src="post.imagePreviewDua"
                          alt="Thumbnail"
                          class="img-thumbnail"
                          style="max-width: 200px; max-height: 200px"
                        />
                      </div>
                      <div>
                        <input
                          type="file"
                          class="d-none"
                          ref="thumbnailFileInputDua"
                          accept="image/*"
                          @change="handleFileUploadDua"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-sm mr-1"
                          @click="triggerThumbnailFileDua"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label>Image Hero Dua</label>
                      <input
                        @change="handleFileUpload('image_hero_dua', $event)"
                        ref="thumbnailFileInputDua"
                        type="file"
                        class="form-control"
                      />
                    </div> -->
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Title Dua</label>
                      <input
                        v-model="post.title_dua"
                        type="text"
                        class="form-control"
                        placeholder="Title Dua"
                      />
                    </div>
                    <div class="form-group">
                      <label>Description Dua</label>
                      <textarea
                        ref="summernoteDua"
                        class="form-control summernote"
                        v-model="post.desc_dua"
                        rows="3"
                        placeholder="Description Dua"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- Section 3 -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Section 3</h4>
                </div>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Title Tiga</label>
                      <input
                        v-model="post.title_tiga"
                        type="text"
                        class="form-control"
                        placeholder="Title Tiga"
                      />
                    </div>
                    <div class="form-group">
                      <label>Description Tiga</label>
                      <textarea
                        ref="summernoteTiga"
                        class="form-control summernote"
                        v-model="post.desc_tiga"
                        rows="3"
                        placeholder="Description Tiga"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div v-show="true" class="mb-3">
                        <img
                          :src="post.imagePreviewTiga"
                          alt="Thumbnail"
                          class="img-thumbnail"
                          style="max-width: 200px; max-height: 200px"
                        />
                      </div>
                      <div>
                        <input
                          type="file"
                          class="d-none"
                          ref="thumbnailFileInputTiga"
                          accept="image/*"
                          @change="handleFileUploadTiga"
                        />
                        <button
                          type="button"
                          class="btn btn-primary btn-sm mr-1"
                          @click="triggerThumbnailFileTiga"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label>Image Hero Tiga</label>
                      <input
                        @change="handleFileUpload('image_hero_tiga', $event)"
                        ref="thumbnailFileInputTiga"
                        type="file"
                        class="form-control"
                      />
                    </div> -->
                  </div>
                </div>

                <!-- Section 4 -->
                <div class="row mb-4">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>URL Daftar</label>
                      <input
                        v-model="post.url_daftar"
                        type="text"
                        class="form-control"
                        placeholder="URL Daftar"
                      />
                    </div>
                  </div>
                </div>

                <!-- Meta SEO -->
                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Meta SEO</h4>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_title"
                      type="text"
                      class="form-control"
                      placeholder="Meta Title"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Meta Description</label
                  >
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_desc"
                      type="text"
                      class="form-control"
                      placeholder="Meta Description"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Keywords</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_keyword"
                      type="text"
                      class="form-control"
                      placeholder="Meta Keywords"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>

                <!-- Buttons -->
                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1"
                >
                  Kembali
                </button>
                <button
                  type="button"
                  @click="submit"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";
import defaultURL from "@/config";

export default {
  name: "Edit Event BS",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const thumbnailFileInputSatu = ref(null);
    const thumbnailFileInputDua = ref(null);
    const thumbnailFileInputTiga = ref(null);
    const post = ref({
      universitas: "",
      slug: "",
      hastag: "",
      title_satu: "",
      desc_satu: "",
      image_hero_satu: null,
      imagePreviewSatu: null,
      url_button: "",
      title_dua: "",
      desc_dua: "",
      image_hero_dua: null,
      imagePreviewDua: null,
      title_tiga: "",
      desc_tiga: "",
      image_hero_tiga: null,
      imagePreviewTiga: null,
      url_daftar: "",
      meta_title: "",
      meta_desc: "",
      meta_keyword: "",
      is_publish: "true",
      user: null,
    });
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
          //throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const summernoteSatu = ref(null);
    const summernoteDua = ref(null);
    const summernoteTiga = ref(null);
    const token = computed(() => store.state.token);

    const fetchData = async () => {
      const id = route.params.id;
      try {
        const response = await axiosInstance.get(`/events-bs/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          const data = response.data.data;
          const imageUrlSatu = `${defaultURL}/images/events/${data.image_hero_satu}`;
          const imageUrlDua = `${defaultURL}/images/events/${data.image_hero_dua}`;
          const imageUrlTiga = `${defaultURL}/images/events/${data.image_hero_tiga}`;
          post.value = {
            ...post.value,
            universitas: data.universitas,
            slug: data.slug,
            hastag: data.hastag,
            title_satu: data.title_satu,
            desc_satu: data.desc_satu,
            image_hero_satu: data.image_hero_satu,
            imagePreviewSatu: data.image_hero_satu ? imageUrlSatu : null,
            url_button: data.url_button,
            title_dua: data.title_dua,
            desc_dua: data.desc_dua,
            image_hero_dua: data.image_hero_dua,
            imagePreviewDua: data.image_hero_dua ? imageUrlDua : null,
            title_tiga: data.title_tiga,
            desc_tiga: data.desc_tiga,
            image_hero_tiga: data.image_hero_tiga,
            imagePreviewTiga: data.image_hero_tiga ? imageUrlTiga : null,
            url_daftar: data.url_daftar,
            meta_title: data.meta_title,
            meta_desc: data.meta_desc,
            meta_keyword: data.meta_keyword,
            is_publish: data.is_publish,
            user: post.value.user,
          };
          $(summernoteSatu.value).summernote("code", post.value.desc_satu);
          $(summernoteDua.value).summernote("code", post.value.desc_dua);
          $(summernoteTiga.value).summernote("code", post.value.desc_tiga);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const submit = async () => {
      post.value.desc_satu = $(summernoteSatu.value).summernote("code");
      post.value.desc_dua = $(summernoteDua.value).summernote("code");
      post.value.desc_tiga = $(summernoteTiga.value).summernote("code");
      const formData = new FormData();

      //image_hero_satu

      Object.keys(post.value).forEach((key) => {
        if (
          key !== "image_hero_satu" &&
          key !== "imagePreviewSatu" &&
          post.value[key] !== ""
        ) {
          formData.append(key, post.value[key]);
        }
      });

      if (post.value.image_hero_satu instanceof File) {
        formData.append("image_hero_satu", post.value.image_hero_satu);
      } else if (typeof post.value.image_hero_satu === "string") {
        formData.append("image_hero_satu", post.value.image_hero_satu);
      }

      //image_hero_dua
      Object.keys(post.value).forEach((key) => {
        if (
          key !== "image_hero_dua" &&
          key !== "imagePreviewDua" &&
          post.value[key] !== ""
        ) {
          formData.append(key, post.value[key]);
        }
      });

      if (post.value.image_hero_dua instanceof File) {
        formData.append("image_hero_dua", post.value.image_hero_dua);
      } else if (typeof post.value.image_hero_dua === "string") {
        formData.append("image_hero_dua", post.value.image_hero_dua);
      }

      //image_hero_tiga
      Object.keys(post.value).forEach((key) => {
        if (
          key !== "image_hero_tiga" &&
          key !== "imagePreviewTiga" &&
          post.value[key] !== ""
        ) {
          formData.append(key, post.value[key]);
        }
      });

      if (post.value.image_hero_tiga instanceof File) {
        formData.append("image_hero_tiga", post.value.image_hero_tiga);
      } else if (typeof post.value.image_hero_tiga === "string") {
        formData.append("image_hero_tiga", post.value.image_hero_tiga);
      }

      try {
        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const response = await axiosInstance.post(
          `/events-bs-update/${route.params.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          if (response.data.success === false) {
            Swal.fire("Error", `Error: ${response.data.message}`, "error");
          } else {
            Swal.fire("Success", "Data updated successfully", "success");
            router.push({ name: "Event BS" });
          }
        }
      } catch (error) {
        Swal.fire("Error", `Error: ${error.message}`, "error");
      }
    };

    const handleFileUploadSatu = (event) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      // Validate file type
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      post.value.image_hero_satu = file;
      post.value.imagePreviewSatu = URL.createObjectURL(file);
    };

    const handleFileUploadDua = (event) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      // Validate file type
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      post.value.image_hero_dua = file;
      post.value.imagePreviewDua = URL.createObjectURL(file);
    };

    const handleFileUploadTiga = (event) => {
      const file = event.target.files[0];

      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      // Validate file type
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      post.value.image_hero_tiga = file;
      post.value.imagePreviewTiga = URL.createObjectURL(file);
    };

    const triggerThumbnailFileSatu = () => {
      if (thumbnailFileInputSatu.value) {
        thumbnailFileInputSatu.value.click();
      } else {
        console.error("thumbnailFileInputSatu is not available.");
      }
    };

    const triggerThumbnailFileDua = () => {
      if (thumbnailFileInputDua.value) {
        thumbnailFileInputDua.value.click();
      } else {
        console.error("thumbnailFileInputDua is not available.");
      }
    };

    const triggerThumbnailFileTiga = () => {
      if (thumbnailFileInputTiga.value) {
        thumbnailFileInputTiga.value.click();
      } else {
        console.error("thumbnailFileInputTiga is not available.");
      }
    };

    // const triggerThumbnailFileSatu = () => {
    //   thumbnailFileInputSatu.value.click();
    // };

    // const triggerThumbnailFileDua = () => {
    //   thumbnailFileInputDua.value.click();
    // };

    // const triggerThumbnailFileTiga = () => {
    //   thumbnailFileInputTiga.value.click();
    // };
    const checkTitleExistence = async () => {
      try {
        const response = await axiosInstance.get(
          `/blog-slug/${post.value.slug}`,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        console.log("Response data:", response.data);

        // Check if the response indicates the title already exists
        if (
          response.data.success &&
          response.data.message === "Title Sudah Digunakan"
        ) {
          Swal.fire({
            title: "Warning",
            text: "The slug already exists, please choose another title.",
            icon: "warning",
            confirmButtonText: "OK",
          });
          post.value.slug = ""; // Clear the slug if it exists
        }
      } catch (error) {
        console.error("Error checking slug existence", error);
      }
    };

    const generateSlug = async () => {
      post.value.slug = post.value.universitas
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "");

      await checkTitleExistence();
    };
    const goBack = () => {
      router.push({ name: "Event BS" });
    };

    onMounted(async () => {
      await fetchUser();
      await fetchData();
      await nextTick();
      $(summernoteSatu.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.desc_satu = contents;
          },
        },
      });

      $(summernoteDua.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.desc_dua = contents;
          },
        },
      });

      $(summernoteTiga.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.desc_tiga = contents;
          },
        },
      });
    });

    return {
      post,
      handleFileUploadSatu,
      handleFileUploadDua,
      handleFileUploadTiga,
      triggerThumbnailFileSatu,
      triggerThumbnailFileDua,
      triggerThumbnailFileTiga,
      generateSlug,
      submit,
      goBack,
      summernoteSatu,
      summernoteDua,
      summernoteTiga,
      thumbnailFileInputSatu,
      thumbnailFileInputDua,
      thumbnailFileInputTiga,
    };
  },
};
</script>
