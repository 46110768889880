<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">Slider</a>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <!-- Button Add -->
      <router-link
        :to="{ name: 'Tambah Slider Award Home' }"
        class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Data {{ currentRouteName }}</h4>
              <!-- Search bar -->
              <div class="d-flex align-items-center">
                <input
                  v-model="searchQuery"
                  @input="handleSearch"
                  class="form-control mr-2"
                  placeholder="Cari berdasarkan nama..."
                  style="width: 250px"
                />
                <button @click="handleSearch" class="btn btn-primary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table header-border table-responsive-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Deskripsi</th>
                    <th>Image Desktop</th>
                    <th>Image Mobile</th>
                    <th>Url</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="!filteredData || !filteredData.length"
                    class="text-center"
                  >
                    <td colspan="7">No Data</td>
                  </tr>
                  <tr v-for="(item, index) in paginatedData" :key="item.id">
                    <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                    <td>{{ truncateText(item.title, 50) }}</td>
                    <td>{{ truncateText(item.deskripsi, 50) }}</td>
                    <td>
                      <img
                        :src="getImageUrl(item, 'desktop')"
                        :alt="item.alt || 'Gambar desktop'"
                        class="w-100"
                        style="width: 100px; height: 100px; border-radius: 10px"
                      />
                    </td>
                    <td>
                      <img
                        :src="getImageUrl(item, 'mobile')"
                        :alt="item.alt || 'Gambar mobile'"
                        class="w-100"
                        style="width: 100px; height: 100px; border-radius: 10px"
                      />
                    </td>
                    <td>{{ truncateText(item.url, 15) }}</td>
                    <td>{{ item.is_publish ? "Publish" : "Unpublish" }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'Edit Slider Award Home',
                          params: { id: item.id },
                        }"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fa fa-pencil color-muted"></i>
                      </router-link>
                      <button
                        type="button"
                        @click="confirmDelete(item.id)"
                        class="btn btn-dark btn-rounded"
                      >
                        <i class="fa fa-trash color-muted"></i>
                      </button>
                      <!-- <router-link :to="{ name: 'View Slider Hero', params: { id: item.id } }" class="btn btn-success btn-rounded">
                                                <i class="fa fa-eye color-muted"></i>
                                        </router-link> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-center">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import defaultURL from "@/config";

export default {
  name: "Slider Award Home",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentRouteName = computed(() => route.name);
    const message = ref("You are not logged in!");
    const id_kategori = 10;
    const Sh = ref([]);

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get(
          `/slider-kategori/${id_kategori}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            Sh.value = data.data;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        // .then(() => {
        //     store.dispatch("setAuth", false);
        //     store.dispatch("setToken", { token: null, expiry: null });
        //     localStorage.clear();
        //     window.location.href = '/'
        // });
      }
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteData(id);
        }
      });
    };

    const deleteData = async (id) => {
      try {
        const response = await axiosInstance.delete(`/slider/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Error", `Failed to delete data: ${error.message}`, "error");
      }
    };

    const getImageUrl = (item, type) => {
      if (type === "desktop") {
        return `${defaultURL}/images/slider/desktop/${item?.image_desktop}`;
      } else if (type === "mobile") {
        return `${defaultURL}/images/slider/mobile/${item?.image_mobile}`;
      }
      return ""; // Return empty string if no image is available
    };

    const truncateText = (text, length) => {
      return text.length > length ? text.slice(0, length) + "..." : text;
    };

    const filteredData = computed(() => {
      return Sh.value.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredData.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      Sh,
      fetchData,
      deleteData,
      confirmDelete,
      getImageUrl,
      searchQuery,
      handleSearch,
      filteredData,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
      truncateText,
    };
  },
};
</script>

<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}

table {
  table-layout: fixed;
  width: 100%;
}

thead th {
  font-size: 14px;
}

th,
td {
  word-wrap: break-word;
  white-space: normal;
}

.table-responsive {
  overflow-x: auto;
}
</style>
