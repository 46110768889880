<template>
  <div
    class="modal fade"
    id="JyModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ formData.id ? "Edit Data" : "Tambah Data" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="error" class="error-message">{{ error }}</div>
          <div class="mb-3">
            <label for="judul" class="form-label">Title</label>
            <input
              type="text"
              class="form-control"
              id="judul"
              v-model="formData.judul"
              maxlength="50"
            />
          </div>
          <div class="mb-3">
            <label for="tahun" class="form-label">Tahun</label>
            <input
              type="number"
              class="form-control"
              id="tahun"
              v-model="formData.tahun"
            />
          </div>
          <div class="mb-3">
            <label for="deskripsi" class="form-label">Deskripsi</label>
            <textarea
              ref="summernote"
              class="form-control summernote"
              id="deskripsi"
              v-model="formData.deskripsi"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger light"
            data-dismiss="modal"
          >
            Tutup
          </button>
          <button type="button" @click="submit" class="btn btn-primary">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, watch, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "ModalJy",
  props: {
    isEdit: Boolean,
    editData: Object,
  },
  setup(props, { emit }) {
    const formData = reactive({
      judul: "",
      tahun: "",
      deskripsi: "",
      user: null,
    });
    const store = useStore();
    const summernote = ref(null);
    const error = ref("");
    const token = computed(() => store.state.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          formData.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
          //throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const validateForm = () => {
      if (!formData.judul || !formData.tahun || !formData.deskripsi) {
        error.value = "All fields are required";
        return false;
      }
      error.value = "";
      return true;
    };

    watch(
      () => props.editData,
      (newValue) => {
        nextTick(() => {
          if (newValue) {
            formData.judul = newValue.judul;
            formData.tahun = newValue.tahun;
            formData.deskripsi = newValue.deskripsi;
            formData.user = formData.user;
            if ($("#deskripsi").length > 0) {
              $("#deskripsi").summernote("code", formData.deskripsi);
            }
          } else {
            formData.judul = "";
            formData.tahun = "";
            formData.deskripsi = "";
            formData.user = "";
            if ($("#deskripsi").length > 0) {
              $("#deskripsi").summernote("reset");
            }
          }
        });
      },
      { immediate: true }
    );

    const submit = () => {
      formData.deskripsi = $("#deskripsi").summernote("code");
      if (!validateForm()) return;

      if (props.isEdit) {
        emit("updateData", formData);
      } else {
        emit("createData", formData);
      }
    };

    onMounted(() => {
      $(summernote.value).summernote({
        height: 200, // Tinggi editor
        callbacks: {
          onChange: (contents) => {
            formData.deskripsi = contents; // Update post.deskripsi saat konten berubah
          },
        },
      });
      fetchUser();
    });

    return {
      formData,
      error,
      submit,
      summernote,
      fetchUser,
    };
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>
