<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Division</label>
                  <div class="col-sm-9">
                    <select
                      v-model="post.id_division"
                      @change="onDivisionChange"
                      class="form-control"
                    >
                      <option value="" disabled>Pilih Division</option>
                      <option
                        v-for="division in divisionList"
                        :key="division.id"
                        :value="division.id"
                      >
                        {{ division.name_division }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" v-if="jobsList.length > 0">
                  <label class="col-sm-3 col-form-label">Jobs</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_type" class="form-control">
                      <option value="" disabled>Pilih Jobs</option>
                      <option
                        v-for="job in jobsList"
                        :key="job.id"
                        :value="job.id"
                      >
                        {{ job.name_type }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Company</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_company" class="form-control">
                      <option value="">Pilih Kantor</option>
                      <option value="1">Pusat</option>
                      <option value="2">Cabang</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    rows="3"
                  ></textarea>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Foto</label>
                  <div class="col-sm-9">
                    <div v-if="post.imagePreview" class="mb-3">
                      <img
                        :src="post.imagePreview"
                        alt="Thumbnail"
                        class="img-thumbnail"
                        style="max-width: 200px; max-height: 200px"
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        class="d-none"
                        ref="fileInput"
                        accept="image/*"
                        @change="handleFileUpload"
                      />
                      <button
                        type="button"
                        class="btn btn-primary btn-sm mr-1"
                        @click="triggerFilePicker"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Total Position</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.total_position"
                      type="Number"
                      class="form-control"
                      placeholder="Total Position"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Waktu Pekerja</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.waktu_pekerja"
                      type="text"
                      class="form-control"
                      placeholder="Waktu Pekerja"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Pengalaman</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.pengalaman"
                      type="text"
                      class="form-control"
                      placeholder="Pengalaman"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>

                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1"
                >
                  Kembali
                </button>
                <button
                  type="button"
                  @click="submit"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";
import defaultURL from "@/config";

export default {
  name: "Edit Career",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const imagePreview = ref(null);
    const fileInput = ref(null);
    const currentRouteName = computed(() => route.name);
    const post = ref({
      id_division: "",
      id_type: "",
      name_type: "",
      id_company: "",
      deskripsi: "",
      waktu_pekerja: "",
      pengalaman: "",
      is_publish: "true",
      image: null,
      imagePreview: null,
      total_position: "",
      user: null,
    });
    const divisionList = ref([]);
    const jobsList = ref([]);

    const token = computed(() => store.state.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          handleAuthError();
        }
      } catch (error) {
        handleAuthError();
      }
    };

    const fetchCareer = async () => {
      const careerId = route.params.id;
      try {
        const response = await axiosInstance.get(`/career/${careerId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          const imageUrl = `${defaultURL}/images/career/${response.data.data.images}`;
          post.value = {
            ...post.value,
            id_division: response.data.data.id_division,
            id_type: response.data.data.id_type,
            id_company: response.data.data.id_company,
            deskripsi: response.data.data.deskripsi,
            waktu_pekerja: response.data.data.waktu_pekerja,
            pengalaman: response.data.data.pengalaman,
            is_publish: response.data.data.is_publish || "true",
            user: post.value.user || null,
            image: response.data.data.images || null,
            imagePreview: response.data.data.image ? imageUrl : null,
            total_position: response.data.data.total_position,
          };
          imagePreview.value = `${defaultURL}/images/career/${response.data.data.images}`;
          $(summernote.value).summernote("code", post.value.deskripsi);
          if (post.value.id_division) {
            await fetchJobsByDivision(post.value.id_division);
          }
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to fetch career data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const fetchDivisionList = async () => {
      try {
        const response = await axiosInstance.get("/division", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          divisionList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const fetchJobsByDivision = async (divisionId) => {
      try {
        const response = await axiosInstance.get(
          `/jobs-division/${divisionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
        if (response.status === 200) {
          jobsList.value = [...response.data.data];
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const onDivisionChange = async () => {
      if (post.value.id_division) {
        await fetchJobsByDivision(post.value.id_division);
      } else {
        jobsList.value = [];
      }
    };

    const goBack = () => {
      router.push({ name: "Career" });
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      // Validate file type
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // If validation passes, assign the file and create a preview
      post.value.image = file;
      post.value.imagePreview = URL.createObjectURL(file);
    };

    const triggerFilePicker = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };

    const submit = async () => {
      post.value.deskripsi = $("#deskripsi").summernote("code");
      const careerId = route.params.id;
      const formData = new FormData();
      formData.append("id_division", post.value.id_division);
      formData.append("id_type", post.value.id_type);
      formData.append("id_company", post.value.id_company);
      formData.append("waktu_pekerja", post.value.waktu_pekerja);
      formData.append("pengalaman", post.value.pengalaman);
      formData.append("deskripsi", post.value.deskripsi);
      formData.append("is_publish", post.value.is_publish);
      formData.append("user", post.value.user);
      formData.append("total_position", post.value.total_position);

      Object.keys(post.value).forEach((key) => {
        if (
          key !== "image" &&
          key !== "imagePreview" &&
          post.value[key] !== ""
        ) {
          formData.append(key, post.value[key]);
        }
      });

      if (post.value.image instanceof File) {
        formData.append("image", post.value.image);
      } else if (typeof post.value.image === "string") {
        formData.append("image", post.value.image);
      }

      try {
        const response = await axiosInstance.post(
          `/career-update/${careerId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error updating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await fetchDivisionList();
      await fetchCareer();
      await nextTick();
      $(summernote.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.deskripsi = contents;
          },
        },
      });
    });

    watch(post, (newValue) => {}, { deep: true });
    return {
      currentRouteName,
      post,
      divisionList,
      jobsList,
      onDivisionChange,
      submit,
      goBack,
      handleFileUpload,
      triggerFilePicker,
      imagePreview,
      fileInput,
      summernote,
    };
  },
};
</script>
