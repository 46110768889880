import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import Profile from "@/views/Profile.vue";
import Sertifikat from "@/views/Sertifikat.vue";
import kategori from "@/views/Kategori.vue";
import Blog from "@/views/Blog.vue";
import AddBlog from "@/views/Posts/AddBlog.vue";
import EditBlog from "@/views/Posts/EditBlog.vue";
import Award from "@/views/Award.vue";
import AddAward from "@/views/Posts/AddAward.vue";
import EditAward from "@/views/Posts/EditAward.vue";
import ArtikelEvent from "@/views/ArtikelEvent.vue";
import AddArtikelEvent from "@/views/Posts/AddArtikelEvent.vue";
import EditArtikelEvent from "@/views/Posts/EditArtikelEvent.vue";
import EventRecap from "@/views/EventRecap.vue";
import AddEventRecap from "@/views/Posts/AddEventRecap.vue";
import EditEventRecap from "@/views/Posts/EditEventRecap.vue";
import Testimoni from "@/views/Testimoni.vue";
import AddTestimoni from "@/views/Posts/AddTestimoni.vue";
import EditTestimoni from "@/views/Posts/EditTestimoni.vue";

import store from "../store/index"; // Import the Vuex store
import HubungiKami from "@/views/HubungiKami.vue";
import TentangKami from "@/views/TentangKami.vue";
import SubKategori from "@/views/SubKategori.vue";
import AddTk from "@/views/Posts/AddTk.vue";
import EditTk from "@/views/Posts/EditTk.vue";
import Journey from "@/views/Journey.vue";
import ViewTk from "@/views/Posts/ViewTk.vue";
import AddSliderHero from "@/views/Posts/AddSliderHero.vue";
import EditSliderHero from "@/views/Posts/EditSliderHero.vue";
import ViewSliderHero from "@/views/Posts/ViewSliderHero.vue";
import SliderHero from "@/views/SliderHero.vue";
import SliderProductHome from "@/views/SliderProductHome.vue";
import AddSliderProductHome from "@/views/Posts/AddSliderProductHome.vue";
import EditSliderProductHome from "@/views/Posts/EditSliderProductHome.vue";
import SliderAwardHome from "@/views/SliderAwardHome.vue";
import AddSliderAwardHome from "@/views/Posts/AddSliderAwardHome.vue";
import EditSliderAwardHome from "@/views/Posts/EditSliderAwardHome.vue";
import Testimonial from "@/views/Testimonial.vue";
import AddTesti from "@/views/Posts/AddTesti.vue";
import EditTesti from "@/views/Posts/EditTesti.vue";
//import ViewTesti from "@/views/Posts/ViewTesti.vue";
import Product from "@/views/Product.vue";
import AddProduct from "@/views/Posts/AddProduct.vue";
import EditProduct from "@/views/Posts/EditProduct.vue";
import ViewProduct from "@/views/Posts/ViewProduct.vue";
import Bussines from "@/views/Bussines.vue";
import AddBussines from "@/views/Posts/AddBussines.vue";
import EditBussines from "@/views/Posts/EditBussines.vue";
import Teknologi from "@/views/Teknologi.vue";
import AddTeknologi from "@/views/Posts/AddTeknologi.vue";
import EditTeknologi from "@/views/Posts/EditTeknologi.vue";
import UserList from "@/views/UserList.vue";
import AddUser from "@/views/Posts/AddUser.vue";
import EditUser from "@/views/Posts/EditUser.vue";
import Career from "@/views/Career.vue";
import AddCareer from "@/views/Posts/AddCareer.vue";
import EditCareer from "@/views/Posts/EditCareer.vue";
import Division from "@/views/Division.vue";
import Jobs from "@/views/Jobs.vue";
import Logger from "@/views/Logger.vue";
import LoggerDetail from "@/views/LoggerDetail.vue";
import Knowledge from "@/views/Knowledge.vue";
import AddKnowledge from "@/views/Posts/AddKnowledge.vue";
import EditKnowledge from "@/views/Posts/EditKnowledge.vue";
import SectionHead from "@/views/SectionHead.vue";
import AddSectionHead from "@/views/Posts/AddSectionHead.vue";
import EditSectionHead from "@/views/Posts/EditSectionHead.vue";
import Api from "@/views/Api.vue";
import FileManager from "@/views/FileManager.vue";
import UserLevel from "@/views/UserLevel.vue";
import AddLevel from "@/views/Posts/AddLevel.vue";
import EditLevel from "@/views/Posts/EditLevel.vue";
import Swal from "sweetalert2";
import EventBS from "@/views/EventBS.vue";
import AddEventBS from "@/views/Posts/AddEventBS.vue";
import EditEventBS from "@/views/Posts/EditEventBS.vue";
import Pelamar from "@/views/Pelamar.vue";
import ContactForm from "@/views/ContactForm.vue";
import axios from "../axios";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "11" &&
        v !== "16" &&
        v !== "17" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/fm",
    name: "File Manager",
    component: FileManager,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "11" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/docs",
    name: "API",
    component: Api,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/division",
    name: "Division",
    component: Division,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "11" &&
        v !== "16"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "11" &&
        v !== "16"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/sertifikat",
    name: "Sertifikat",
    component: Sertifikat,
    meta: { requiresAuth: true },
  },
  {
    path: "/kategori",
    name: "Kategori",
    component: kategori,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/sub-kategori",
    name: "Sub Kategori",
    component: SubKategori,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/hubungi-kami",
    name: "Hubungi Kami",
    component: HubungiKami,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/contact-form",
    name: "Contact Form",
    component: ContactForm,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "17") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/tentang-kami",
    name: "Tentang Kami",
    component: TentangKami,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/tk/add",
    name: "Tambah Tentang Kami",
    component: AddTk,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/tk/edit/:id",
    name: "Edit Tentang Kami",
    component: EditTk,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/tk/view/:id",
    name: "View Tentang Kami",
    component: ViewTk,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-hero",
    name: "Slider Hero",
    component: SliderHero,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-hero/add",
    name: "Tambah Slider Hero",
    component: AddSliderHero,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-hero/edit/:id",
    name: "Edit Slider Hero",
    component: EditSliderHero,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  // {
  //   path: "/slider-hero/view/:id",
  //   name: "View Slider Hero",
  //   component: ViewSliderHero,
  //   meta: { requiresAuth: true },
  //beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  {
    path: "/slider-product-home",
    name: "Slider Product Home",
    component: SliderProductHome,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-product-home/add",
    name: "Tambah Slider Product Home",
    component: AddSliderProductHome,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-product-home/edit/:id",
    name: "Edit Slider Product Home",
    component: EditSliderProductHome,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-award-home",
    name: "Slider Award Home",
    component: SliderAwardHome,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-award-home/add",
    name: "Tambah Slider Award Home",
    component: AddSliderAwardHome,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/slider-award-home/edit/:id",
    name: "Edit Slider Award Home",
    component: EditSliderAwardHome,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/blog/add",
    name: "Tambah Blog",
    component: AddBlog,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/blog/edit/:id",
    name: "Edit Blog",
    component: EditBlog,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/award",
    name: "Award",
    component: Award,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/award/add",
    name: "Tambah Award",
    component: AddAward,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/award/edit/:id",
    name: "Edit Award",
    component: EditAward,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/art-event",
    name: "Artikel Event",
    component: ArtikelEvent,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/art-event/add",
    name: "Tambah Artikel Event",
    component: AddArtikelEvent,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/art-event/edit/:id",
    name: "Edit Artikel Event",
    component: EditArtikelEvent,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/event-recap",
    name: "Event Recap",
    component: EventRecap,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/event-recap/add",
    name: "Tambah Event Recap",
    component: AddEventRecap,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/event-recap/edit/:id",
    name: "Edit Event Recap",
    component: EditEventRecap,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testimoni",
    name: "Testimoni",
    component: Testimoni,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testimoni/add",
    name: "Tambah Testimoni",
    component: AddTestimoni,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testimoni/edit/:id",
    name: "Edit Testimoni",
    component: EditTestimoni,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/journey",
    name: "Journey",
    component: Journey,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testi",
    name: "Testimonial",
    component: Testimonial,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testi/add",
    name: "Tambah Testimonial",
    component: AddTesti,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/testi/edit/:id",
    name: "Edit Testimonial",
    component: EditTesti,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  // {
  //   path: "/testi/view/:id",
  //   name: "View Testimonial",
  //   component: ViewTesti,
  //   meta: { requiresAuth: true },
  // beforeEnter: (to, from, next) => {
  //     const isAuthenticated = store.getters.isAuthenticated;
  //     const token = store.getters.token;
  //     const tokenExpiry = store.getters.tokenExpiry;
  //     if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
  //       next("/");
  //     else next();
  //   },
  // },
  {
    path: "/products",
    name: "Product",
    component: Product,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/product/add",
    name: "Tambah Product",
    component: AddProduct,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/product/edit/:id",
    name: "Edit Product",
    component: EditProduct,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/product/view/:id",
    name: "View Product",
    component: ViewProduct,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/bussines",
    name: "Bussines",
    component: Bussines,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/bussines/a",
    name: "Tambah Bussines",
    component: AddBussines,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/bussines/edit/:id",
    name: "Edit Bussines",
    component: EditBussines,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/teknologi",
    name: "Teknologi",
    component: Teknologi,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/teknologi/add",
    name: "Tambah Teknologi",
    component: AddTeknologi,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/teknologi/edit/:id",
    name: "Edit Teknologi",
    component: EditTeknologi,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/logger",
    name: "Logger",
    component: Logger,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8") {
        next("/");
      } else {
        next();
      }
    },
  },

  {
    path: "/logger-view/:id",
    name: "Detail Logger",
    component: LoggerDetail,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/SectionHead",
    name: "Section Head",
    component: SectionHead,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/sectionhead/add",
    name: "Tambah Section Head",
    component: AddSectionHead,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/sectionhead/edit/:id",
    name: "Edit Section Head",
    component: EditSectionHead,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "16") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/Knowledge",
    name: "Knowledge",
    component: Knowledge,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/knowledge/add",
    name: "Tambah Knowledge",
    component: AddKnowledge,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/knowledge/edit/:id",
    name: "Edit Knowledge",
    component: EditKnowledge,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "16" &&
        v !== "18"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/levels",
    name: "User Level",
    component: UserLevel,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/levels/add",
    name: "Tambah Level",
    component: AddLevel,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/levels/edit/:id",
    name: "Edit Level",
    component: EditLevel,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/users",
    name: "User List",
    component: UserList,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/users/add",
    name: "Tambah User",
    component: AddUser,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/users/edit/:id",
    name: "Edit User",
    component: EditUser,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/career",
    name: "Career",
    component: Career,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "11") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/career/add",
    name: "Tambah Career",
    component: AddCareer,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "11") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/career/edit/:id",
    name: "Edit Career",
    component: EditCareer,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "11") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/pelamar",
    name: "Pelamar",
    component: Pelamar,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (v !== "1" && v !== "5" && v !== "8" && v !== "11") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/eventbs",
    name: "Event BS",
    component: EventBS,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "17" &&
        v !== "16"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/eventbs/add",
    name: "Tambah Event BS",
    component: AddEventBS,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "17" &&
        v !== "16"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/eventbs/edit/:id",
    name: "Edit Event BS",
    component: EditEventBS,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const isAuthenticated = store.getters.isAuthenticated;
      const token = store.getters.token;
      const tokenExpiry = store.getters.tokenExpiry;
      const v = store.getters.v;
      if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
        next("/");
      } else if (
        v !== "1" &&
        v !== "5" &&
        v !== "8" &&
        v !== "17" &&
        v !== "16"
      ) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Navigation guard to protect routes
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters.isAuthenticated;
//   const tokenExpiry = store.getters.tokenExpiry;

//   if (to.matched.some((record) => record.meta.requiresAuth) && (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())) {
//     next('/');
//   } else {
//     next();
//   }
// });

// export default router;

// Navigation guard to protect routes
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters.isAuthenticated;
//   const token = store.getters.token;
//   const tokenExpiry = store.getters.tokenExpiry;
//   if (
//     to.matched.some((record) => record.meta.requiresAuth) &&
//     (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
//   ) {
//     next("/");
//   } else {
//     next();
//   }
//   // if (to.path === "/" && isAuthenticated) {
//   //   next({ name: "Dashboard" });
//   // } else if (to.path !== "/" && !isAuthenticated) {
//   //   next("/");
//   // } else if (
//   //   to.matched.some((record) => record.meta.requiresAuth) &&
//   //   (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
//   // ) {
//   //   next("/");
//   // } else {
//   //   next();
//   // }
// });

// export default router;

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters.isAuthenticated;
//   const token = store.getters.token;
//   const tokenExpiry = store.getters.tokenExpiry;

//   // Jika pengguna sudah login dan mencoba mengakses halaman login ("/")
//   if (
//     to.path === "/" &&
//     isAuthenticated &&
//     token &&
//     new Date(tokenExpiry) > new Date()
//   ) {
//     // Redirect ke dashboard
//     next("/dashboard");
//   }
//   // Jika pengguna belum login atau session sudah habis dan mencoba mengakses halaman yang memerlukan autentikasi
//   else if (
//     to.matched.some((record) => record.meta.requiresAuth) &&
//     (!isAuthenticated || !token || new Date(tokenExpiry) < new Date())
//   ) {
//     // Tampilkan alert session expired jika token sudah kadaluarsa
//     if (new Date(tokenExpiry) < new Date()) {
//       Swal.fire({
//         title: "Session Expired",
//         text: "Your session has expired. Please log in again.",
//         icon: "warning",
//         confirmButtonText: "OK",
//       }).then(() => {
//         // Setelah alert ditutup, arahkan pengguna ke halaman login
//         store.dispatch("setAuth", false);
//         store.dispatch("setToken", { token: null, expiry: null });
//         localStorage.clear();
//         next("/"); // Arahkan ke halaman login setelah alert
//       });
//     } else {
//       // Jika session belum habis, arahkan langsung ke halaman login tanpa alert
//       next("/");
//     }
//   }
//   // Jika tidak ada masalah, lanjutkan ke halaman yang dituju
//   else {
//     next();
//   }
// });

// export default router;
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const token = store.getters.token;
  const tokenExpiry = store.getters.tokenExpiry;

  // Fungsi untuk menampilkan alert dan logout
  const handleSessionExpired = () => {
    Swal.fire({
      title: "Session Expired",
      text: "Your session has expired or is invalid. Please log in again.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      // Set autentikasi menjadi false dan hapus token
      store.dispatch("setAuth", false);
      store.dispatch("setToken", { token: null, expiry: null });
      localStorage.clear();
      next("/"); // Arahkan ke halaman login setelah logout
    });
  };

  // Jika pengguna belum login atau tidak terautentikasi
  if (!isAuthenticated || !token || new Date(tokenExpiry) < new Date()) {
    if (to.path !== "/") {
      next("/");
    } else {
      next();
    }
  } else if (
    to.path === "/" &&
    isAuthenticated &&
    token &&
    new Date(tokenExpiry) > new Date()
  ) {
    next("/dashboard");
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    new Date(tokenExpiry) < new Date()
  ) {
    handleSessionExpired();
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    token &&
    isAuthenticated
  ) {
    // Interceptor untuk menangani error 401
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleSessionExpired();
        }
        return Promise.reject(error);
      }
    );
    next();
  } else {
    next();
  }
});

export default router;
