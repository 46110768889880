<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <!-- <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div> -->

      <!-- Button Add -->
      <button
        type="button"
        class="btn btn-primary mb-4"
        data-toggle="modal"
        data-target="#msModal"
        @click="openModal()"
      >
        Tambah
      </button>
      <!-- End Button Add -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-group">
                <h4 class="card-title mb-3">
                  Cari Data {{ currentRouteName }}
                </h4>
                <!-- Search bar -->
                <div class="d-flex align-items-center">
                  <input
                    v-model="searchQuery"
                    @input="handleSearch"
                    class="form-control mr-2"
                    placeholder="Cari berdasarkan nama..."
                    style="width: 250px"
                  />
                  <button @click="handleSearch" class="btn btn-primary btn-sm">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nama</th>
                      <th>Email</th>
                      <th>No. Telepon</th>
                      <th>Status</th>
                      <th>Jabatan</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="!filteredData || !filteredData.length"
                      class="text-center"
                    >
                      <td colspan="6">No Data</td>
                    </tr>
                    <tr v-for="(item, index) in paginatedData" :key="item.id">
                      <td>
                        {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                      </td>
                      <td>{{ item.nama_lengkap }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.no_tlp }}</td>
                      <td>{{ item.is_publish ? "Publish" : "No" }}</td>
                      <td>{{ item.jabatan == 1 ? "Sales" : "CRM" }}</td>
                      <td>
                        <button
                          @click="openModal(true, item)"
                          type="button"
                          class="btn btn-primary btn-rounded btn-sm mr-1 mb-1"
                          data-toggle="modal"
                          data-target="#msModal"
                        >
                          <i class="fa fa-pencil color-muted"></i>
                        </button>
                        <button
                          @click="deleteData(item.id)"
                          type="button"
                          class="btn btn-dark btn-rounded btn-sm"
                        >
                          <i class="fa fa-trash color-muted"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <ModalHub
    :isEdit="isEdit"
    :editData="editData"
    @createData="createData"
    @updateData="updateData"
  />
</template>
<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalHub from "../components/modal/ModalHub.vue";

export default {
  name: "Hubungi Kami",
  components: {
    ModalHub,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const sales = ref([]);
    const isEdit = ref(false);
    const editData = ref(null);
    const currentRouteName = computed(() => router.name);
    const message = ref("You are not logged in!");

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const openModal = (edit = false, data = null) => {
      isEdit.value = edit;
      editData.value = data;
      nextTick(() => {
        $("#msModal").modal("show");
      });
    };

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get("/sales", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            sales.value = data.data;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        // .then(() => {
        //     store.dispatch("setAuth", false);
        //     store.dispatch("setToken", { token: null, expiry: null });
        //     localStorage.clear();
        //     window.location.href = '/'
        // });
      }
    };

    const createData = async (formData) => {
      try {
        const response = await axiosInstance.post("/sales", formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          await Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $("#msModal").modal("hide");
        } else {
          const errorData = await response.data;
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const updateData = async (formData) => {
      try {
        const response = await axiosInstance.post(
          `/sales-update/${editData.value.id}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        if (response.status === 200) {
          await Swal.fire({
            title: "Success",
            text: "Data updated successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $("#msModal").modal("hide");
        } else {
          const errorData = await response.data;
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const deleteData = async (id) => {
      try {
        const response = await axiosInstance.delete(`/sales/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          await Swal.fire({
            title: "Success",
            text: "Data deleted successfully!",
            icon: "success",
            confirmButtonText: "OK",
          });
          await fetchData();
          $("#msModal").modal("hide");
        } else {
          const errorData = await response.json();
          Swal.fire({
            title: "Error",
            text: `Error: ${errorData.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const filteredData = computed(() => {
      return sales.value.filter((item) =>
        item.nama_lengkap
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase())
      );
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredData.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    onMounted(() => {
      fetchData();
    });

    watch([isAuthenticated, token], async ([newAuth, newToken]) => {
      if (newAuth && newToken) {
        await fetchData();
      }
    });

    return {
      currentRouteName,
      message,
      sales,
      isEdit,
      editData,
      openModal,
      createData,
      updateData,
      deleteData,
      fetchData,
      searchQuery,
      handleSearch,
      filteredData,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
    };
  },
};
</script>
