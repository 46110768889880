<template>
  <div
    class="modal fade show"
    style="display: block"
    tabindex="-1"
    role="dialog"
    v-if="isVisible"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-lg">
        <div class="modal-header">
          <h5 class="modal-title">Form</h5>
          <button type="button" class="close" @click="closeModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="judulError" class="error-message">{{ judulError }}</div>
          <div class="basic-form">
            <div class="form-group">
              <label>Division</label>
              <input
                type="text"
                class="form-control"
                v-model="division.name_division"
                placeholder="Nama Divisi"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger light"
            @click="closeModal"
          >
            Tutup
          </button>
          <button type="button" @click.prevent="submit" class="btn btn-primary">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "ModalDivision",
  props: {
    divisionToEdit: {
      type: Object,
      default: null,
    },
  },
  emits: ["refreshData", "closeModal"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const division = reactive({
      id: null,
      name_division: "",
      user: null,
    });
    const isVisible = ref(true);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const judulError = ref("");

    const validateForm = () => {
      if (!division.name_division) {
        judulError.value = "Nama division tidak boleh kosong";
        return false;
      }
      judulError.value = "";
      return true;
    };

    watch(
      () => props.divisionToEdit,
      (newVal) => {
        if (newVal) {
          division.id = newVal.id;
          division.name_division = newVal.name_division;
        } else {
          division.id = "";
          division.name_division = "";
        }
      },
      { immediate: true }
    );

    const submit = async () => {
      if (
        !token.value ||
        !tokenExpiry.value ||
        new Date() > new Date(tokenExpiry.value) ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
        return;
      }
      if (!validateForm()) return;

      const userResponse = await axiosInstance.get("/user", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.value}`,
        },
      });

      if (userResponse.status !== 200) {
        await Swal.fire({
          title: "Error",
          text: `Error: Failed to fetch user data`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }

      const userData = await userResponse.data;

      try {
        const payload = {
          name_division: division.name_division, // Pastikan field ini sesuai dengan ekspektasi API
          user: userData.id,
        };

        let response;
        if (division.id) {
          // Update division
          response = await axiosInstance.put(
            `/division/${division.id}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token.value}`,
              },
            }
          );
        } else {
          // Create new division
          response = await axiosInstance.post("/division", payload, {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          });
        }

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: division.id
              ? "Division berhasil diperbarui"
              : "Division berhasil ditambahkan",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            division.name_division = ""; // Kosongkan input form setelah submit
            emit("refreshData"); // Refresh data setelah submit
            emit("closeModal"); // Tutup modal
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const closeModal = () => {
      emit("closeModal");
    };

    return {
      division,
      judulError,
      submit,
      closeModal,
      isVisible,
    };
  },
};
</script>
