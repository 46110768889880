<template>
  <div class="content-body" style="padding-top: 7rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-group">
                <h4 class="card-title mb-3">
                  Cari Data {{ currentRouteName }}
                </h4>
                <!-- Total Count Display -->
                <p class="text-muted">Total Pelamar: {{ filteredCount }}</p>
                <!-- Search bar -->
                <div class="d-flex align-items-center mb-4">
                  <!-- Search Input -->
                  <input
                    v-model="searchQuery"
                    @input="handleSearch"
                    class="form-control mr-3"
                    placeholder="Search..."
                    style="border-radius: 8px; width: 250px"
                  />

                  <!-- Filter Dropdown -->
                  <div class="dropdown mr-3">
                    <button
                      class="btn btn-outline-primary"
                      type="button"
                      id="filterDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                      "
                    >
                      <i class="fa fa-filter"></i> Filter
                    </button>
                    <div
                      class="dropdown-menu p-3"
                      aria-labelledby="filterDropdown"
                      style="width: 300px"
                    >
                      <h6 class="dropdown-header"></h6>
                      <div class="d-flex flex-wrap">
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="positionFilter" class="filter-label"
                            >Position</label
                          >
                          <select
                            v-model="selectedPosition"
                            id="positionFilter"
                            class="form-control"
                          >
                            <option value="">All Positions</option>
                            <option
                              v-for="position in uniquePositions"
                              :key="position"
                              :value="position"
                            >
                              {{ position }}
                            </option>
                          </select>
                        </div>
                        <!-- Month Filter -->
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="monthFilter" class="filter-label"
                            >Month</label
                          >
                          <select
                            v-model="selectedMonth"
                            id="monthFilter"
                            class="form-control"
                          >
                            <option value="">Select Month</option>
                            <option
                              v-for="month in months"
                              :key="month.value"
                              :value="month.value"
                            >
                              {{ month.text }}
                            </option>
                          </select>
                        </div>

                        <!-- Year Filter -->
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="yearFilter" class="filter-label"
                            >Year</label
                          >
                          <select
                            v-model="selectedYear"
                            id="yearFilter"
                            class="form-control"
                          >
                            <option value="">Select Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>

                        <!-- Sort Order Filter -->
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="sortFilter" class="filter-label"
                            >Sort</label
                          >
                          <select
                            v-model="sortOrder"
                            id="sortFilter"
                            class="form-control"
                          >
                            <option value="desc">Descending</option>
                            <option value="asc">Ascending</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Reset Button -->
                  <button @click="resetFilters" class="btn btn-link">
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nama</th>
                      <th>Posisi</th>
                      <th>Tanggal Lamar</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="!filteredData || !filteredData.length"
                      class="text-center"
                    >
                      <td colspan="6">No Data</td>
                    </tr>
                    <tr
                      v-else
                      v-for="(item, index) in paginatedData"
                      :key="item.id"
                    >
                      <td>
                        {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                      </td>
                      <td>{{ item.nama_lengkap }}</td>
                      <td>{{ item.name_division }}</td>
                      <td>{{ formatDate(item.created_at) }}</td>
                      <td>
                        <!-- Updated "view" button -->
                        <button
                          @click="viewDetails(item)"
                          class="btn btn-warning btn-rounded btn-sm mr-1 mb-1"
                        >
                          <i class="fa fa-eye color-muted"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-dark btn-rounded btn-sm mr-1 mb-1"
                          @click="deletePelamar(item.id)"
                        >
                          <i class="fa fa-trash color-muted"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Detail Modal Component -->
    <ModalCareer
      v-if="showModal"
      :showModal="showModal"
      :selectedItem="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { reactive, ref, watch, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalCareer from "@/components/modal/ModalCareer.vue";

export default {
  components: {
    ModalCareer,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const currentRouteName = computed(() => router.currentRoute.value.name);
    const tK = ref([]);

    // Authentication checks
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} 🕒 ${hours}:${minutes}`;
    };

    const selectedMonth = ref("");
    const selectedYear = ref("");
    const sortOrder = ref("desc");
    const selectedPosition = ref("");
    const resetFilters = () => {
      searchQuery.value = "";
      selectedMonth.value = "";
      selectedYear.value = "";
      selectedPosition.value = "";
      sortOrder.value = "desc";
      handleSearch(); // Refresh data if needed
    };
    const filteredCount = computed(() => filteredData.value.length);

    // Generate unique positions dynamically from the data
    const uniquePositions = computed(() => {
      const positions = tK.value.map((item) => item.name_division);
      return [...new Set(positions)].filter(Boolean); // Remove duplicates and null values
    });

    // Generate months dynamically
    const months = Array.from({ length: 12 }, (v, i) => ({
      value: (i + 1).toString().padStart(2, "0"),
      text: new Date(0, i).toLocaleString("en", { month: "long" }),
    }));

    // Generate years dynamically based on the data or set a default range
    const currentYear = new Date().getFullYear();
    const years = computed(() => {
      const minYear = 2024; // set the start year as per your requirement or data availability
      return Array.from(
        { length: currentYear - minYear + 1 },
        (v, i) => currentYear - i
      );
    });

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: "Session expired or invalid",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const showModal = ref(false);
    const selectedItem = ref({});

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get("/pelamar-cms", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (getData.status === 200) {
          const data = getData.data;
          if (data.code === 200) {
            tK.value = data.data;
          }
        }
      } catch (error) {
        // Check if error response exists and if status is 404
        if (error.response && error.response.status === 404) {
          // No alert for 404 errors
          console.warn("Data not found or empty.");
        } else {
          // Show alert for other types of errors
          Swal.fire({
            title: "Error",
            text: `Error: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    };

    // const filteredData = computed(() => {
    //   return tK.value.filter((item) => {
    //     const name = item.nama_lengkap ? item.nama_lengkap.toLowerCase() : "";
    //     const jobType = item.name_division
    //       ? item.name_division.toLowerCase()
    //       : "";

    //     return (
    //       name.includes(searchQuery.value.toLowerCase()) ||
    //       jobType.includes(searchQuery.value.toLowerCase())
    //     );
    //   });
    // });

    const filteredData = computed(() => {
      return tK.value
        .filter((item) => {
          // Filtering based on search query for name or job type
          const name = item.nama_lengkap ? item.nama_lengkap.toLowerCase() : "";
          const jobType = item.name_division
            ? item.name_division.toLowerCase()
            : "";

          const matchesSearchQuery =
            name.includes(searchQuery.value.toLowerCase()) ||
            jobType.includes(searchQuery.value.toLowerCase());

          // Filtering based on month and year if selected
          const itemDate = new Date(item.created_at); // Assuming `created_at` is the date field
          const itemMonth = itemDate.getMonth() + 1; // getMonth() is 0-indexed, so add 1
          const itemYear = itemDate.getFullYear();

          const matchesMonth =
            !selectedMonth.value || parseInt(selectedMonth.value) === itemMonth;
          const matchesYear =
            !selectedYear.value || parseInt(selectedYear.value) === itemYear;
          // Filter by Position
          const matchesPosition =
            !selectedPosition.value ||
            item.name_division === selectedPosition.value;

          // Log for debugging
          console.log(`Filtering item with created_at: ${item.created_at}`);
          console.log(
            `Selected Month: ${selectedMonth.value}, Item Month: ${itemMonth}`
          );
          console.log(
            `Selected Year: ${selectedYear.value}, Item Year: ${itemYear}`
          );

          // Return true if all conditions are met
          return (
            matchesSearchQuery && matchesMonth && matchesYear && matchesPosition
          );
        })
        .sort((a, b) => {
          // Sort based on `created_at` in ascending or descending order
          if (sortOrder.value === "asc") {
            return new Date(a.created_at) - new Date(b.created_at);
          }
          return new Date(b.created_at) - new Date(a.created_at);
        });
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredData.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const viewDetails = async (item) => {
      try {
        const response = await axiosInstance.get(`/pelamar-cms/${item.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200 && response.data.code === 200) {
          selectedItem.value = response.data.data;
          showModal.value = true; // Open the modal
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Failed to fetch details: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const closeModal = () => {
      showModal.value = false; // Close the modal
      selectedItem.value = {}; // Clear the selected item data
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const deletePelamar = async (id) => {
      try {
        const confirmation = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        });

        if (confirmation.isConfirmed) {
          const response = await axiosInstance.delete(`/pelamar-delete/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          });

          if (response.status === 200) {
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            tK.value = tK.value.filter((item) => item.id !== id);
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the record.",
              "error"
            );
          }
        }
      } catch (error) {
        Swal.fire("Error!", `Error: ${error.message}`, "error");
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      tK,
      fetchData,
      searchQuery,
      filteredData,
      handleSearch,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
      showModal,
      selectedItem,
      viewDetails,
      closeModal,
      deletePelamar,
      formatDate,
      months,
      years,
      selectedMonth,
      selectedYear,
      sortOrder,
      resetFilters,
      filteredCount,
      selectedPosition,
      uniquePositions,
    };
  },
};
</script>
