<template>
  <div class="modal-backdrop fade show"></div>
  <div
    class="modal fade show d-block"
    tabindex="-1"
    role="dialog"
    aria-labelledby="productDetailModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="productDetailModalLabel">
            Product Detail
          </h5>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>{{ product.title }}</h5>
          <img
            :src="getImageUrl(product)"
            alt="Product Image"
            class="img-fluid mb-3"
          />
          <p>
            <strong>URL Produk:</strong>
            <a :href="product.url_produk" target="_blank">{{
              product.url_produk
            }}</a>
          </p>
          <p><strong>Deskripsi:</strong></p>
          <div v-html="product.deskripsi"></div>
          <p><strong>Rating:</strong></p>
          <span class="star-review d-inline-block mb-2 fs-16 wspace-no">
            <i
              v-for="i in 5"
              :key="i"
              :class="getStarClass(product.rating, i)"
              class="fa fs-16"
            ></i>
          </span>
          <p>
            <strong>Status:</strong> {{ product.is_publish ? "Publish" : "No" }}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axiosInstance from "../../axios";
import defaultURL from "@/config";

export default {
  props: ["productId"],
  setup(props, { emit }) {
    const product = ref({});

    const fetchProductDetail = async () => {
      try {
        const response = await axiosInstance.get(`/product/${props.productId}`);
        if (response.status === 200 && response.data.code === 200) {
          product.value = response.data.data;
        } else {
          console.error("Failed to fetch product detail");
        }
      } catch (error) {
        console.error("Error fetching product detail:", error);
      }
    };

    const getImageUrl = (product) => {
      return product.image
        ? `${defaultURL}/images/product/${product.image}`
        : "";
    };

    const getStarClass = (rating, index) => {
      return rating && rating >= index
        ? "fa-star text-orange"
        : "fa-star text-gray";
    };

    const close = () => {
      emit("close");
    };

    onMounted(() => {
      fetchProductDetail();
    });

    return {
      product,
      getImageUrl,
      getStarClass,
      close,
    };
  },
};
</script>
