<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Tambah Career' }"
              >Data Career</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <!-- Division Dropdown -->
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Division</label>
                  <div class="col-sm-9">
                    <select
                      v-model="post.id_division"
                      @change="onDivisionChange"
                      class="form-control"
                    >
                      <option value="" disabled>Pilih Division</option>
                      <option
                        v-for="division in divisionList"
                        :key="division.id"
                        :value="division.id"
                      >
                        {{ division.name_division }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- Jobs Dropdown (Based on Division) -->
                <div class="form-group row" v-if="jobsList.length > 0">
                  <label class="col-sm-3 col-form-label">Jobs</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_type" class="form-control">
                      <option value="" disabled>Pilih Jobs</option>
                      <option
                        v-for="job in jobsList"
                        :key="job.id"
                        :value="job.id"
                      >
                        {{ job.name_type }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Company</label>
                  <div class="col-sm-9">
                    <select v-model="post.id_company" class="form-control">
                      <option value="">Pilih Kantor</option>
                      <option value="1">Pusat</option>
                      <option value="2">Cabang</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    rows="3"
                  ></textarea>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Foto</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          @change="handleFileUpload"
                          type="file"
                          class="custom-file-input"
                        />
                        <label class="custom-file-label">{{
                          post.image ? post.image.name : "Pilih file"
                        }}</label>
                      </div>
                    </div>
                    <!-- Preview Gambar -->
                    <div v-if="imagePreview" class="mt-3">
                      <img
                        :src="imagePreview"
                        alt="Preview"
                        class="img-thumbnail"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Total Position</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.total_position"
                      type="number"
                      class="form-control"
                      placeholder="Total Position"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Waktu Pekerja</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.waktu_pekerja"
                      type="text"
                      class="form-control"
                      placeholder="Waktu Pekerja"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Pengalaman</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.pengalaman"
                      type="text"
                      class="form-control"
                      placeholder="Pengalaman"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>

                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1"
                >
                  Kembali
                </button>
                <button
                  type="button"
                  @click="submit"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah Career",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const imagePreview = ref(null);
    const currentRouteName = computed(() => route.name);
    const post = ref({
      id_division: "",
      id_type: "",
      name_type: "",
      id_company: "",
      deskripsi: "",
      waktu_pekerja: "",
      pengalaman: "",
      is_publish: "true",
      image: null,
      total_position: "",
      user: null,
    });
    const divisionList = ref([]);
    const jobsList = ref([]);

    const token = computed(() => store.state.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to fetch user data",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const fetchDivisionList = async () => {
      try {
        const response = await axiosInstance.get("/division", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          divisionList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const fetchJobsByDivision = async (divisionId) => {
      try {
        const response = await axiosInstance.get(
          `/jobs-division/${divisionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );
        if (response.status === 200) {
          jobsList.value = response.data.data;
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const onDivisionChange = async () => {
      if (post.value.id_division) {
        await fetchJobsByDivision(post.value.id_division);
      } else {
        jobsList.value = []; // Kosongkan daftar jobs jika tidak ada division yang dipilih
      }
    };

    const goBack = () => {
      router.push({ name: "Career" });
    };

    const submit = async () => {
      post.value.deskripsi = $(summernote.value).summernote("code");
      const formData = new FormData();
      formData.append("id_division", post.value.id_division);
      formData.append("id_type", post.value.id_type);
      formData.append("id_company", post.value.id_company);
      formData.append("deskripsi", post.value.deskripsi);
      formData.append("total_position", post.value.total_position);
      formData.append("waktu_pekerja", post.value.waktu_pekerja);
      formData.append("pengalaman", post.value.pengalaman);
      formData.append("is_publish", post.value.is_publish);
      formData.append("user", post.value.user);
      if (post.value.image) {
        formData.append("image", post.value.image);
      }

      try {
        const response = await axiosInstance.post("/career", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error creating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      // Validate file type
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      post.value.image = file;

      // Create image preview
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };

    onMounted(async () => {
      await fetchUser();
      await fetchDivisionList();
      await nextTick();

      // Initialize Summernote after the DOM is fully rendered
      $(summernote.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.deskripsi = contents;
          },
        },
      });
    });

    return {
      currentRouteName,
      post,
      divisionList,
      jobsList,
      onDivisionChange,
      submit,
      goBack,
      imagePreview,
      handleFileUpload,
      summernote,
    };
  },
};
</script>
