<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Post</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Knowledge' }"
              >Data Knowledge</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Judul</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.title"
                      @input="generateSlug"
                      type="text"
                      class="form-control"
                      placeholder="Judul Post"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Slug URL</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.slug"
                      type="text"
                      class="form-control"
                      placeholder="Slug URL"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Icon</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          @change="handleFileUploadIcon"
                          type="file"
                          class="custom-file-input"
                        />
                        <label class="custom-file-label">{{
                          post.icon ? post.icon.name : "Pilih file"
                        }}</label>
                      </div>
                    </div>
                    <!-- Preview Gambar -->
                    <div v-if="imagePreviewIcon" class="mt-3">
                      <img
                        :src="imagePreviewIcon"
                        alt="Preview"
                        class="img-thumbnail"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Thumbnail</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          @change="handleFileUploadSingle"
                          type="file"
                          class="custom-file-input"
                        />
                        <label class="custom-file-label">{{
                          post.image ? post.image.name : "Pilih file"
                        }}</label>
                      </div>
                    </div>
                    <!-- Preview Gambar -->
                    <div v-if="imagePreview" class="mt-3">
                      <img
                        :src="imagePreview"
                        alt="Preview"
                        class="img-thumbnail"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Image Caption</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.alt_image"
                      type="text"
                      class="form-control"
                      placeholder="Input Caption Image"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Deskripsi</label>
                  <textarea
                    ref="summernote"
                    class="form-control summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    rows="3"
                  ></textarea>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Keyword Tag</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.hastag"
                      type="text"
                      class="form-control"
                      placeholder="Input Keyword Tag"
                    />
                  </div>
                </div>

                <div class="card-header2 mt-5 mb-3">
                  <h4 class="card-title">Meta SEO</h4>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Title</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_title"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Title"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"
                    >Meta Description</label
                  >
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_deskripsi"
                      type="text"
                      class="form-control"
                      placeholder="Max. 160 Characters"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Content</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_content"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Content"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Meta Name</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.meta_name"
                      type="text"
                      class="form-control"
                      placeholder="Input Meta Content"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Status</label>
                  <div class="col-sm-9">
                    <select v-model="post.is_publish" class="form-control">
                      <option value="true">Publish</option>
                      <option value="false">Unpublish</option>
                    </select>
                  </div>
                </div>
                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1"
                >
                  Kembali
                </button>
                <button
                  type="button"
                  @click="submit"
                  class="btn btn-primary btn-sm mr-1"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah Knowledge",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const currentRouteName = computed(() => router.name);
    const imagePreview = ref(null);
    const imagePreviewIcon = ref(null);
    const post = ref({
      title: "",
      slug: "",
      icon: null,
      alt_image: "",
      deskripsi: "",
      hastag: "",
      meta_title: "",
      meta_deskripsi: "",
      meta_content: "",
      meta_name: "",
      is_publish: "true",
      user: null,
      id_kategori: 17,
    });

    const token = computed(() => store.state.token);
    const fileInput = ref(null);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        });
        post.value.user = response.data.id;
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const handleFileUploadSingle = (event) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      post.value.image = file;
    };

    const handleFileUploadIcon = (event) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      post.value.icon = file;
    };

    const goBack = () => {
      router.push({ name: "Knowledge" });
    };

    const generateSlug = async () => {
      post.value.slug = post.value.title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "");
    };

    const submit = async () => {
      post.value.deskripsi = $("#deskripsi").summernote("code");
      const formData = new FormData();
      formData.append("title", post.value.title);
      formData.append("slug", post.value.slug);
      formData.append("alt_image", post.value.alt_image);
      formData.append("deskripsi", post.value.deskripsi);
      formData.append("hastag", post.value.hastag);
      formData.append("meta_title", post.value.meta_title);
      formData.append("meta_deskripsi", post.value.meta_deskripsi);
      formData.append("meta_content", post.value.meta_content);
      formData.append("meta_name", post.value.meta_name);
      formData.append("is_publish", post.value.is_publish);
      formData.append("user", post.value.user);
      formData.append("id_kategori", post.value.id_kategori);
      if (post.value.image) {
        formData.append("image", post.value.image);
      }
      if (post.value.icon) {
        formData.append("icon", post.value.icon);
      }

      try {
        const response = await axiosInstance.post("/blog", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error creating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await nextTick();
      $(summernote.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.deskripsi = contents;
          },
        },
      });
    });

    return {
      currentRouteName,
      post,
      fileInput,
      handleFileUploadIcon,
      handleFileUploadSingle,
      generateSlug,
      submit,
      goBack,
      imagePreview,
      imagePreviewIcon,
      summernote,
    };
  },
};
</script>
