<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <!-- <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div> -->

      <!-- Button Add -->
      <router-link
        :to="{ name: 'Tambah Event BS' }"
        class="btn btn-primary mb-4"
        >Tambah</router-link
      >
      <!-- Tutup -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-group">
                <h4 class="card-title mb-3">
                  Cari Data {{ currentRouteName }}
                </h4>
                <p class="text-muted">Total Articles: {{ filteredCount }}</p>
                <!-- Search bar -->
                <div class="d-flex align-items-center">
                  <input
                    v-model="searchQuery"
                    @input="handleSearch"
                    class="form-control mr-3"
                    placeholder="Search..."
                    style="border-radius: 8px; width: 250px"
                  />

                  <!-- Filter Dropdown -->
                  <div class="dropdown mr-3">
                    <button
                      class="btn btn-outline-primary"
                      type="button"
                      id="filterDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                      "
                    >
                      <i class="fa fa-filter"></i> Filter
                    </button>
                    <div
                      class="dropdown-menu p-3"
                      aria-labelledby="filterDropdown"
                      style="width: 300px"
                    >
                      <h6 class="dropdown-header">Add Filter</h6>
                      <div class="d-flex flex-wrap">
                        <!-- Month Filter -->
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="monthFilter" class="filter-label"
                            >Month</label
                          >
                          <select
                            v-model="selectedMonth"
                            id="monthFilter"
                            class="form-control"
                          >
                            <option value="">Select Month</option>
                            <option
                              v-for="month in months"
                              :key="month.value"
                              :value="month.value"
                            >
                              {{ month.text }}
                            </option>
                          </select>
                        </div>

                        <!-- Year Filter -->
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="yearFilter" class="filter-label"
                            >Year</label
                          >
                          <select
                            v-model="selectedYear"
                            id="yearFilter"
                            class="form-control"
                          >
                            <option value="">Select Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>

                        <!-- Sort Order Filter -->
                        <div
                          class="filter-option"
                          style="width: 100%"
                          @click.stop
                        >
                          <label for="sortFilter" class="filter-label"
                            >Sort</label
                          >
                          <select
                            v-model="sortOrder"
                            id="sortFilter"
                            class="form-control"
                          >
                            <option value="desc">Descending</option>
                            <option value="asc">Ascending</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Reset Button -->
                  <button @click="resetFilters" class="btn btn-link">
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Universitas</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="!filteredData || !filteredData.length"
                      class="text-center"
                    >
                      <!-- <td colspan="6">No Data</td> -->
                    </tr>
                    <tr v-for="(item, index) in paginatedData" :key="item.id">
                      <td>
                        {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                      </td>
                      <td>{{ item.universitas }}</td>
                      <!-- <td v-html="truncateText(item.deskripsi, 50)"></td> -->
                      <td>{{ item.is_publish ? "Publish" : "No" }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Event BS',
                            params: { id: item.id },
                          }"
                          class="btn btn-primary btn-rounded btn-sm mr-1 mb-1"
                        >
                          <i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <button
                          type="button"
                          @click="confirmDelete(item.id)"
                          class="btn btn-dark btn-rounded btn-sm mr-1 mb-1"
                        >
                          <i class="fa fa-trash color-muted"></i>
                        </button>
                        <button
                          @click="navigateToEvent(item.slug)"
                          class="btn btn-info btn-rounded btn-sm mr-1 mb-1"
                        >
                          <i class="fa fa-eye color-muted"></i>
                        </button>
                        <button
                          @click="navigateToEventFE(item.slug)"
                          class="btn btn-link btn-rounded btn-sm mr-1 mb-1"
                        >
                          <i class="fa fa-eye color-muted"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                      >{{ page }}</a
                    >
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import { mainURL, feURL } from "@/mainURL";

export default {
  name: "Event BS",
  methods: {
    navigateToEvent(slug) {
      const url = `${mainURL}/events/${slug}`;
      window.open(url, "_blank");
    },
    navigateToEventFE(slug) {
      const url = `${feURL}/events/${slug}`;
      window.open(url, "_blank");
    },
  },

  setup() {
    const store = useStore();
    const router = useRoute();
    const currentRouteName = computed(() => router.name);
    const EventBS = ref([]); // Ensure it's initialized as an empty array

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalPages = ref(1);
    const totalItems = ref(0);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const filteredCount = computed(() => filteredData.value.length);

    const selectedMonth = ref("");
    const selectedYear = ref("");
    const sortOrder = ref("desc");
    const resetFilters = () => {
      searchQuery.value = "";
      selectedMonth.value = "";
      selectedYear.value = "";
      sortOrder.value = "desc";
      handleSearch(); // Refresh data if needed
    };

    // Generate months dynamically
    const months = Array.from({ length: 12 }, (v, i) => ({
      value: (i + 1).toString().padStart(2, "0"),
      text: new Date(0, i).toLocaleString("en", { month: "long" }),
    }));

    // Generate years dynamically based on the data or set a default range
    const currentYear = new Date().getFullYear();
    const years = computed(() => {
      const minYear = 2024; // set the start year as per your requirement or data availability
      return Array.from(
        { length: currentYear - minYear + 1 },
        (v, i) => currentYear - i
      );
    });

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    // const fetchData = async (page = 1) => {
    //   try {
    //     const getData = await axiosInstance.get(`/events-bs?page=${page}`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token.value}`,
    //       },
    //     });

    //     const responseData = getData.data;

    //     // Check if `responseData.data` is an array and handle pagination data
    //     if (responseData && Array.isArray(responseData.data)) {
    //       EventBS.value = responseData.data; // The actual data (events/items)
    //       totalItems.value = responseData.total; // Total number of items
    //       currentPage.value = responseData.current_page; // Current page
    //       totalPages.value = Math.ceil(
    //         totalItems.value / responseData.per_page
    //       ); // Total pages
    //     } else {
    //       console.error("Data received is not an array:", responseData.data);
    //       EventBS.value = []; // Fallback to an empty array
    //     }
    //   } catch (error) {
    //     Swal.fire({
    //       title: "Error",
    //       text: `Error: ${error.message}`,
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //   }
    // };

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get(`/events-bs`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            EventBS.value = data.data;
            totalItems.value = data.data.length;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const confirmDelete = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteData(id);
        }
      });
    };

    const deleteData = async (id) => {
      try {
        await axiosInstance.delete(`/events-bs/${id}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        fetchData();
      } catch (error) {
        Swal.fire("Error", `Failed to delete data: ${error.message}`, "error");
      }
    };

    const truncateText = (text, length) => {
      return text.length > length ? text.slice(0, length) + "..." : text;
    };

    const filteredData = computed(() => {
      if (!Array.isArray(EventBS.value)) return [];
      return EventBS.value
        .filter((item) => {
          const itemDate = new Date(item.created_date);
          const itemMonth = itemDate.getMonth() + 1; // getMonth() is 0-indexed, so add 1
          const itemYear = itemDate.getFullYear();

          // Log month and year comparisons for debugging
          console.log(`Filtering item with created_date: ${item.created_date}`);
          console.log(
            `Selected Month: ${selectedMonth.value}, Item Month: ${itemMonth}`
          );
          console.log(
            `Selected Year: ${selectedYear.value}, Item Year: ${itemYear}`
          );

          // Compare month and year as numbers for consistency
          return (
            (!selectedMonth.value ||
              parseInt(selectedMonth.value) === itemMonth) &&
            (!selectedYear.value ||
              parseInt(selectedYear.value) === itemYear) &&
            item.universitas
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
          );
        })
        .sort((a, b) => {
          if (sortOrder.value === "asc")
            return new Date(a.created_date) - new Date(b.created_date);
          return new Date(b.created_date) - new Date(a.created_date);
        });
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredData.value.slice(start, start + itemsPerPage);
    });

    // const totalPages = computed(() => {
    //   return Math.ceil(filteredData.value.length / itemsPerPage);
    // });

    // const nextPage = () => {
    //   if (currentPage.value < totalPages.value) {
    //     currentPage.value++;
    //     fetchData(currentPage.value); // Fetch the next page data
    //   }
    // };

    // const prevPage = () => {
    //   if (currentPage.value > 1) {
    //     currentPage.value--;
    //     fetchData(currentPage.value); // Fetch the previous page data
    //   }
    // };

    // const goToPage = (page) => {
    //   if (page >= 1 && page <= totalPages.value) {
    //     currentPage.value = page;
    //     fetchData(page); // Fetch the selected page data
    //   }
    // };

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    onMounted(() => {
      fetchData(); // Fetch data when the component mounts
    });

    return {
      currentRouteName,
      EventBS,
      filteredData,
      deleteData,
      fetchData,
      confirmDelete,
      searchQuery,
      handleSearch,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
      truncateText,
      months,
      years,
      selectedMonth,
      selectedYear,
      sortOrder,
      resetFilters,
      filteredCount,
    };
  },
};
</script>
