<template>
  <div class="content-body" style="padding-top: 7rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="form-group">
                <h4 class="card-title mb-3">
                  Cari Data {{ currentRouteName }}
                </h4>
                <!-- Search bar -->
                <div class="d-flex align-items-center">
                  <input
                    v-model="searchQuery"
                    @input="handleSearch"
                    class="form-control mr-2"
                    placeholder="Cari berdasarkan nama..."
                    style="width: 250px"
                  />
                  <button @click="handleSearch" class="btn btn-primary btn-sm">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <!-- <table class="table header-border table-responsive-sm"> -->
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nama</th>
                      <th>Subject</th>
                      <th>Company</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="!filteredData || !filteredData.length"
                      class="text-center"
                    >
                      <td colspan="7">No Data</td>
                    </tr>
                    <tr
                      v-else
                      v-for="(item, index) in paginatedData"
                      :key="item.id"
                    >
                      <td>
                        {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                      </td>
                      <td>{{ item.nama }}</td>
                      <td>{{ item.jenis }}</td>
                      <td>{{ item.company }}</td>
                      <td>{{ formatDate(item.created_date) }}</td>

                      <td>
                        <!-- Updated "view" button -->
                        <button
                          @click="viewDetails(item)"
                          class="btn btn-warning btn-rounded btn-sm"
                        >
                          <i class="fa fa-eye color-muted"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Pagination -->
              <nav>
                <ul class="pagination justify-content-end mt-3">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="prevPage"
                      >Previous</a
                    >
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    class="page-item"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="goToPage(page)"
                    >
                      {{ page }}
                    </a>
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      @click="nextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Detail Modal Component -->
    <ModalContactForm
      v-if="showModal"
      :showModal="showModal"
      :selectedItem="selectedItem"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { reactive, ref, watch, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../axios";
import ModalContactForm from "@/components/modal/ModalContactForm.vue";

export default {
  components: {
    ModalContactForm,
  },
  methods: {
    formatDate(value) {
      if (value) {
        const date = new Date(value); // Membuat objek Date dari nilai tanggal
        const day = String(date.getDate()).padStart(2, "0"); // Mendapatkan hari dengan dua digit
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Mendapatkan bulan dengan dua digit (ditambah 1 karena dimulai dari 0)
        const year = date.getFullYear(); // Mendapatkan tahun
        return `${day}-${month}-${year}`; // Menggabungkan menjadi format dd-mm-yyyy
      }
      return "";
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentRouteName = computed(() => router.currentRoute.value.name);
    const tK = ref([]);

    // Authentication checks
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: "Session expired or invalid",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }

    const showModal = ref(false);
    const selectedItem = ref({});

    const currentPage = ref(1);
    const itemsPerPage = 10;
    const searchQuery = ref("");
    const totalItems = ref(0);

    const fetchData = async () => {
      try {
        const getData = await axiosInstance.get("/contact-form-cms", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            tK.value = data.data;
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const filteredData = computed(() => {
      return tK.value.filter((item) => {
        const nameName = item.name ? item.name.toLowerCase() : "";
        const companyName = item.company ? item.company.toLowerCase() : "";

        return (
          nameName.includes(searchQuery.value.toLowerCase()) ||
          companyName.includes(searchQuery.value.toLowerCase())
        );
      });
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredData.value.slice(start, start + itemsPerPage);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage);
    });

    const handleSearch = () => {
      currentPage.value = 1; // Reset to first page on search
    };

    const viewDetails = async (item) => {
      try {
        const response = await axiosInstance.get(
          `/contact-form-cms-id/${item.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        // Check the response and set the selected item
        if (response.status === 200 && response.data.code === 200) {
          selectedItem.value = response.data.data;
          showModal.value = true; // Open the modal
        } else {
          throw new Error("User not found");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          Swal.fire({
            title: "Error",
            text: "User not found.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `Failed to fetch details: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    };

    const closeModal = () => {
      showModal.value = false; // Close the modal
      selectedItem.value = {}; // Clear the selected item data
    };

    const goToPage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      currentRouteName,
      tK,
      fetchData,
      searchQuery,
      filteredData,
      handleSearch,
      paginatedData,
      currentPage,
      totalPages,
      goToPage,
      itemsPerPage,
      nextPage,
      prevPage,
      showModal,
      selectedItem,
      viewDetails,
      closeModal,
    };
  },
};
</script>
