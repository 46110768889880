<template>
  <div class="content-body" style="padding-top: 16rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0)">Info</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Slider Hero' }"
              >Data Slider Hero</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Form {{ currentRouteName }}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Title Slide</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.title"
                      @input="generateSlug"
                      type="text"
                      class="form-control"
                      placeholder="Tittle Slide"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Slug URL</label>
                  <div class="col-sm-9">
                    <input
                      v-model="post.slug"
                      type="text"
                      class="form-control"
                      placeholder="Slug URL"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-sm-3 col-form-label">Deskripsi Slide</label>
                  <textarea
                    ref="summernote"
                    id="deskripsi"
                    v-model="post.deskripsi"
                    type="text"
                    class="form-control"
                    placeholder="Deskripsi Slide"
                  ></textarea>
                </div>
                <div class="form-group">
                  <div class="custom-file">
                    <label for="image_desktop">Desktop Image</label>
                    <input
                      type="file"
                      id="image_desktop"
                      @change="handleFileUpload($event, 'desktop')"
                      class="form-control-file"
                      accept="image/*"
                      required
                    />
                    <img
                      v-if="imagePreviewDesktop"
                      :src="imagePreviewDesktop"
                      alt="Desktop Preview"
                      class="img-thumbnail mb-4"
                      style="max-width: 100px"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="image_mobile">Mobile Image</label>
                  <input
                    type="file"
                    id="image_mobile"
                    @change="handleFileUpload($event, 'mobile')"
                    class="form-control-file"
                    accept="image/*"
                    required
                  />
                  <img
                    v-if="imagePreviewMobile"
                    :src="imagePreviewMobile"
                    alt="Mobile Preview"
                    class="img-thumbnail mb-4"
                    style="max-width: 100px"
                  />
                </div>
                <div class="form-group">
                  <label for="alt">Alt Text</label>
                  <input
                    type="text"
                    id="alt"
                    v-model="post.alt"
                    class="form-control"
                    placeholder="Enter alt text"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="url">URL</label>
                  <input
                    type="text"
                    id="url"
                    v-model="post.url"
                    class="form-control"
                    placeholder="Enter URL"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="is_publish">Publish Status</label>
                  <select
                    id="is_publish"
                    v-model="post.is_publish"
                    class="form-control"
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="true">Publish</option>
                    <option value="false">Unpublish</option>
                  </select>
                </div>
                <button
                  type="button"
                  @click="goBack"
                  class="btn btn-warning btn-sm mr-1"
                >
                  Kembali
                </button>
                <button class="btn btn-primary btn-sm mr-1" @click="submitPost">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah Slider Hero",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const summernote = ref(null);
    const currentRouteName = computed(() => route.name);
    const post = ref({
      title: "",
      slug: "",
      deskripsi: "",
      image_desktop: null,
      image_mobile: null,
      alt: "",
      url: "",
      is_publish: "",
      user: null,
      id_kategori: 8,
    });
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);
    const id_kategori = post.value.id_kategori;
    const imagePreviewDesktop = ref(null);
    const imagePreviewMobile = ref(null);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    // const handleFileUpload = (event, target) => {
    //   const file = event.target.files[0];
    //   if (target === "desktop") {
    //     post.value.image_desktop = file;
    //     imagePreviewDesktop.value = URL.createObjectURL(file);
    //   } else if (target === "mobile") {
    //     post.value.image_mobile = file;
    //     imagePreviewMobile.value = URL.createObjectURL(file);
    //   }
    // };

    const handleFileUpload = (event, target) => {
      const file = event.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes

      // Validate file type
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          title: "Invalid File Type",
          text: "Please select an image with format jpeg, jpg, png, or svg.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        Swal.fire({
          title: "File Too Large",
          text: "Please select an image smaller than 2MB.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      // If validation passes, assign the file and create a preview
      if (target === "desktop") {
        post.value.image_desktop = file;
        imagePreviewDesktop.value = URL.createObjectURL(file);
      } else if (target === "mobile") {
        post.value.image_mobile = file;
        imagePreviewMobile.value = URL.createObjectURL(file);
      }
    };

    const goBack = () => {
      router.push({ name: "Slider Product Home" });
    };

    const checkTitleExistence = async () => {
      try {
        const response = await axiosInstance.get(
          `/blog-slug/${post.value.slug}`,
          {
            headers: {
              Authorization: `Bearer ${token.value}`,
            },
          }
        );

        console.log("Response data:", response.data);

        // Check if the response indicates the title already exists
        if (
          response.data.success &&
          response.data.message === "Title Sudah Digunakan"
        ) {
          Swal.fire({
            title: "Warning",
            text: "The slug already exists, please choose another title.",
            icon: "warning",
            confirmButtonText: "OK",
          });
          post.value.slug = ""; // Clear the slug if it exists
        }
      } catch (error) {
        console.error("Error checking slug existence", error);
      }
    };

    const generateSlug = async () => {
      post.value.slug = post.value.title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/^-+|-+$/g, "");

      await checkTitleExistence();
    };

    const validateForm = () => {
      if (!post.value.image_desktop || !post.value.image_mobile) {
        Swal.fire(
          "Validation Error",
          "Both desktop and mobile images must be provided.",
          "error"
        );
        return false;
      }
      if (!post.value.alt) {
        Swal.fire("Validation Error", "Alt text must be provided.", "error");
        return false;
      }
      if (!post.value.url) {
        Swal.fire("Validation Error", "URL must be provided.", "error");
        return false;
      }
      if (!post.value.is_publish) {
        Swal.fire(
          "Validation Error",
          "Publish status must be selected.",
          "error"
        );
        return false;
      }
      return true;
    };

    const submitPost = async () => {
      post.value.deskripsi = $("#deskripsi").summernote("code");
      if (!validateForm()) return;
      const formData = new FormData();
      formData.append("title", post.value.title);
      formData.append("slug", post.value.slug);
      formData.append("deskripsi", post.value.deskripsi);
      formData.append("image_desktop", post.value.image_desktop);
      formData.append("image_mobile", post.value.image_mobile);
      formData.append("alt", post.value.alt);
      formData.append("url", post.value.url);
      formData.append("is_publish", post.value.is_publish);
      formData.append("user", post.value.user);
      formData.append("id_kategori", id_kategori);
      if (post.value.image_desktop || post.value.image_mobile) {
        formData.append("image_desktop", post.value.image_desktop);
        formData.append("image_mobile", post.value.image_mobile);
      }

      try {
        const response = await axiosInstance.post("/slider", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Data created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error creating data",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await nextTick();
      $(summernote.value).summernote({
        height: 200,
        callbacks: {
          onChange: (contents) => {
            post.value.deskripsi = contents;
          },
        },
      });
    });

    return {
      currentRouteName,
      post,
      handleFileUpload,
      generateSlug,
      submitPost,
      goBack,
      imagePreviewDesktop,
      imagePreviewMobile,
      summernote,
    };
  },
};
</script>

<style scoped>
.content-body {
  padding: 30px;
  background: #f4f4f9;
}
</style>
