<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="page-titles">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'API' }">API Documentation</router-link>
          </li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0)">{{ currentRouteName }}</a>
          </li>
        </ol>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">API Documentation</h4>
            </div>
            <div class="card-body">
              <!-- Div ini akan menjadi tempat target di mana iframe akan dimasukkan -->
              <div id="iframe-container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";
import defaultURL from "@/config";

export default {
  name: "API",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const currentRouteName = computed(() => route.name);
    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    // Buat instance axios atau gunakan axios langsung
    const axiosInstance = axios.create();

    // Axios Interceptor for adding Authorization header automatically
    axiosInstance.interceptors.request.use(
      (config) => {
        const token = store.getters.token; // Ambil token dari Vuex store
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Function to check session validity
    const checkSession = () => {
      if (
        !token.value ||
        !tokenExpiry.value ||
        new Date() > new Date(tokenExpiry.value) ||
        !isAuthenticated.value
      ) {
        Swal.fire({
          title: "Error",
          text: `Session expired or invalid`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          router.push({ name: "Login" });
        });
      }
    };

    // Create and append the iframe dynamically
    const createIframe = () => {
      const iframe = document.createElement("iframe");
      iframe.src = `${defaultURL}/docs`;
      iframe.style.width = "100%";
      iframe.style.height = "100vh";
      iframe.style.border = "1px solid #e0e0e0";
      iframe.style.borderRadius = "20px";

      const container = document.getElementById("iframe-container");
      if (container) {
        container.appendChild(iframe); // Append the iframe to the container
      }
    };

    // Use onMounted hook to check session validity on page load and create iframe
    onMounted(() => {
      checkSession();
      createIframe(); // Panggil fungsi untuk membuat iframe
    });

    return {
      currentRouteName,
    };
  },
};
</script>

<style scoped>
.content-body {
  /* padding: 30px; */
  background: #f4f4f9;
}
</style>
