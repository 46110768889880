<template>
  <div class="content-body" style="padding-top: 6rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Tambah User</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form @submit.prevent="submitForm">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Nama</label>
                    <div class="col-sm-9">
                      <input
                        type="text"
                        v-model="post.name"
                        class="form-control"
                        id="name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Email</label>
                    <div class="col-sm-9">
                      <input
                        type="email"
                        v-model="post.email"
                        class="form-control"
                        id="email"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Password</label>
                    <div class="col-sm-9">
                      <div class="input-group">
                        <input
                          :type="showPassword ? 'text' : 'password'"
                          v-model="post.password"
                          class="form-control"
                          id="password"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-outline-secondary"
                            @click="togglePassword"
                          >
                            <i
                              :class="
                                showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
                              "
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">User Level</label>
                    <div class="col-sm-9">
                      <select v-model="post.users_level" class="form-control">
                        <option
                          v-for="level in userLevels"
                          :key="level.id"
                          :value="level.id"
                        >
                          {{ level.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Status</label>
                    <div class="col-sm-9">
                      <select v-model="post.is_active" class="form-control">
                        <option value="1">Publish</option>
                        <option value="0">Unpublish</option>
                      </select>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="goBack"
                    class="btn btn-warning mr-1 btn-sm mr-1"
                  >
                    Kembali
                  </button>
                  <button type="submit" class="btn btn-primary btn-sm mr-1">
                    Simpan
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";

export default {
  name: "Tambah User",
  setup() {
    const store = useStore();
    const router = useRouter();
    const post = ref({
      name: "",
      email: "",
      password: "",
      users_level: null,
      is_active: "",
      user: null,
    });

    const userLevels = ref([]);
    const showPassword = ref(false);

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    if (
      !token.value ||
      !tokenExpiry.value ||
      new Date() > new Date(tokenExpiry.value) ||
      !isAuthenticated.value
    ) {
      Swal.fire({
        title: "Error",
        text: `Session expired or invalid`,
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        store.dispatch("setAuth", false);
        store.dispatch("setToken", { token: null, expiry: null });
        localStorage.clear();
        window.location.href = "/";
      });
      return;
    }
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          post.value.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to fetch user data",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };

    const fetchUserLevels = async () => {
      try {
        const response = await axiosInstance.get("/user-levels", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          userLevels.value = response.data.data;
        } else {
          throw new Error("Unable to fetch user levels");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const goBack = () => {
      router.push({ name: "User List" });
    };

    const submitForm = async () => {
      if (!post.value.users_level) {
        Swal.fire({
          title: "Error",
          text: "User Level is required.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return; // Prevent form submission if users_level is missing
      }

      const data = {
        name: post.value.name,
        email: post.value.email,
        password: post.value.password,
        users_level: post.value.users_level,
        is_active: post.value.is_active,
      };

      try {
        const response = await axiosInstance.post("/users", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "User created successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            goBack();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Unexpected response from server.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    onMounted(async () => {
      await fetchUser();
      await fetchUserLevels(); // Fetch user levels on mount
    });

    watch(
      () => post.value.users_level,
      (newUsersLevel) => {
        console.log("Selected User Level:", newUsersLevel);
      }
    );

    return {
      fetchUser,
      fetchUserLevels,
      isAuthenticated,
      token,
      tokenExpiry,
      userLevels,
      post,
      submitForm,
      goBack,
      showPassword,
      togglePassword,
    };
  },
};
</script>

<style scoped>
/* Add some basic styling */
</style>
