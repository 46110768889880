<template>
  <div class="content-body" style="padding-top: 10rem">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12 col-xxl-12">
          <div class="row">
            <div class="col-xl-12">
              <div class="card event-detail-bx overflow-hidden">
                <div class="card-media">
                  <img
                    :src="tK?.image ? imageUrl : defaultImage"
                    :alt="tK?.alt_image || 'Gambar default'"
                    class="w-100"
                  />
                </div>

                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center mb-4">
                    <h2
                      class="text-black col-xl-6 p-0 col-xxl-12 mr-auto title mb-3"
                    >
                      {{ tK?.title }}
                    </h2>
                  </div>

                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-xxl-6 mb-3">
                      <div
                        class="media bg-light p-3 rounded align-items-center"
                      >
                        <div class="media-body">
                          <span class="fs-12 d-block mb-1">Total Views</span>
                          <span class="fs-16 text-black"
                            >{{ tK?.views || 0 }} Views</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-xxl-6 mb-3">
                      <div
                        class="media bg-light p-3 rounded align-items-center"
                      >
                        <div class="media-body">
                          <span class="fs-12 d-block mb-1">Tanggal Post</span>
                          <span class="fs-16 text-black">{{
                            formattedDate
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h4 class="fs-20 text-black font-w600">Deskripsi</h4>
                  <p class="fs-14 mb-0">
                    {{ plainDescription }}
                  </p>
                  <br />
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-xxl-6 mb-3">
                      <p>Author : {{ tK?.user.name || "No author" }}</p>
                    </div>
                    <div class="col-lg-4 col-md-6 col-xxl-6 mb-3">
                      <p>Hastag : {{ tK?.hastag || "No hastag" }}</p>
                    </div>
                  </div>

                  <button @click="goBack" class="btn btn-primary">
                    <i class="fa fa-arrow-left"></i> Kembali
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axiosInstance from "../../axios";
import defaultURL from "@/config";

export default {
  name: "View Tentang Kami",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const currentRouteName = computed(() => router.name);
    const tK = ref(null);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const token = computed(() => store.getters.token);
    const tokenExpiry = computed(() => store.getters.tokenExpiry);

    const fetchData = async () => {
      const id = route.params.id;
      try {
        const getData = await axiosInstance.get(`/blog/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });

        if (getData.status === 200) {
          const data = await getData.data;
          if (data.code === 200) {
            tK.value = data.data;
          }
        } else {
          Swal.fire({
            title: "Error",
            text: `Error: ${data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        await Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        // .then(() => {
        //     store.dispatch("setAuth", false);
        //     store.dispatch("setToken", { token: null, expiry: null });
        //     localStorage.clear();
        //     window.location.href = '/'
        // });
      }
    };

    const formattedDate = computed(() => {
      if (tK.value && tK.value.created_date) {
        return new Date(tK.value.created_date).toLocaleDateString("id-ID", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
      return "Date not available";
    });

    const imageUrl = computed(() => {
      return `${defaultURL}/images/blogs/${tK.value?.image}`;
    });

    const defaultImage = computed(() => {
      return "images/hill.jpg";
    });

    const plainDescription = computed(() => {
      const html = tK.value?.deskripsi || "";
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    });

    const goBack = () => {
      router.push({ name: "Tentang Kami" });
    };

    onMounted(() => {
      fetchData();
    });

    return {
      tK,
      fetchData,
      formattedDate,
      imageUrl,
      defaultImage,
      plainDescription,
      goBack,
    };
  },
};
</script>
