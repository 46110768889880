<template>
  <div class="modal fade" id="JobsModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-lg">
        <div class="modal-header">
          <h5 class="modal-title">Form</h5>
          <button type="button" class="close" data-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="judulError" class="error-message">{{ judulError }}</div>
          <div class="basic-form">
            <div class="form-group">
              <label>Division</label>
              <select v-model="formData.id_division" class="form-control">
                <option
                  v-for="division in divisionList"
                  :key="division.id"
                  :value="division.id"
                >
                  {{ division.name_division }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>Jobs</label>
              <input
                type="text"
                class="form-control"
                v-model="formData.name_type"
                id="jobs"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger light"
            data-dismiss="modal"
          >
            Tutup
          </button>
          <button type="button" @click.prevent="submit" class="btn btn-primary">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import axiosInstance from "../../axios";
import Swal from "sweetalert2";

export default {
  name: "ModalJobs",
  props: {
    isEdit: Boolean,
    editData: Object,
  },
  setup(props, { emit }) {
    const formData = reactive({
      id_division: "",
      name_type: "",
      user: null,
    });
    const store = useStore();
    const divisionList = ref([]);
    const judulError = ref("");
    const token = computed(() => store.state.token);

    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get("/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token.value}`,
          },
        });
        if (response.status === 200) {
          formData.user = response.data.id;
        } else {
          Swal.fire({
            title: "Error",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            store.dispatch("setAuth", false);
            store.dispatch("setToken", { token: null, expiry: null });
            localStorage.clear();
            window.location.href = "/";
          });
          //throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          store.dispatch("setAuth", false);
          store.dispatch("setToken", { token: null, expiry: null });
          localStorage.clear();
          window.location.href = "/";
        });
      }
    };
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/division", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.token}`,
          },
        });
        if (response.status === 200) {
          divisionList.value = response.data.data;
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    };

    const validateForm = () => {
      if (!formData.id_division || !formData.name_type) {
        judulError.value = "All fields are required";
        return false;
      }
      judulError.value = "";
      return true;
    };

    watch(
      () => props.editData,
      (newValue) => {
        if (newValue) {
          formData.id_division = newValue.id_division;
          formData.name_type = newValue.name_type;
          formData.user = newValue.created_by;
        } else {
          formData.id_division = "";
          formData.name_type = "";
          formData.user = "";
        }
      },
      { immediate: true }
    );

    const submit = () => {
      if (!validateForm()) return;

      const payload = {
        ...formData,
        id_division: formData.id_division,
      };

      if (props.isEdit) {
        emit("updateData", payload);
      } else {
        emit("createData", payload);
      }
    };

    onMounted(() => {
      fetchUser();
      fetchCategories();
    });

    return {
      formData,
      divisionList,
      judulError,
      submit,
    };
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>
